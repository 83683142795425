import React from "react";

import theme from "src/styles/theme";

import { DefaultTextStyle, SubTitleTextStyle, TitleTextStyle } from "./index.styled";

interface TextPropsType {
  _medium?: boolean;
  _bold?: boolean;
  _center?: boolean;
  _fontType?: string;
  _style?: object;
  _title?: boolean;
  _subTitle?: boolean;
  _onClick?(): void;
  children?: React.ReactNode | string | [];
  className?: string;
}

const Text = ({
  _medium,
  _bold,
  _center,
  _fontType,
  _style,
  _title,
  _subTitle,
  _onClick,
  children,
  className,
}: TextPropsType) => {
  const styles = {
    _medium,
    _bold,
    _center,
  };

  const fontClass = theme.fontFamily.filter(x => x.class === _fontType)[0]?.fontFamily;

  if (_title) {
    return (
      <TitleTextStyle onClick={_onClick} className={className + " " + fontClass} style={{ ..._style }} {...styles}>
        {children}
      </TitleTextStyle>
    );
  }

  if (_subTitle) {
    return (
      <SubTitleTextStyle onClick={_onClick} className={className + " " + fontClass} style={{ ..._style }} {...styles}>
        {children}
      </SubTitleTextStyle>
    );
  }

  return (
    <DefaultTextStyle onClick={_onClick} className={className + " " + fontClass} style={{ ..._style }} {...styles}>
      {children}
    </DefaultTextStyle>
  );
};

export default Text;
