import React from "react";

import { useNavigate } from "react-router";

import { SearchBar } from "src/components/block";

interface StoreSearchBarInterface {
  _storeCount: number;
}

const StoreSearchBar = ({ _storeCount }: StoreSearchBarInterface) => {
  const navigate = useNavigate();

  const storeSearch = async (keyword: string): Promise<void> => {
    if (keyword === "") return navigate(`/admin/store?page=1`);
    navigate(`/admin/store?page=1&kw=${keyword}`);
  };

  return (
    <SearchBar
      _placeholder="store id / 상호명 / 점주명 / 휴대폰 번호"
      _title=""
      _onClick={storeSearch}
      _searchCount={_storeCount}
    />
  );
};

export default StoreSearchBar;
