import React, { ReactNode } from "react";

import styled from "styled-components";

import Text from "@/components/elements/Text";

import theme from "src/styles/theme";

const CardTemplate = styled.div`
  display: block;
  white-space: pre-wrap;
  font-size: 14px;
  position: relative;
  transform: scale(1);
  overflow: hidden;

  h6 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    background: ${theme.color.blue_20};
    padding: 10px;
  }

  section {
    width: 100% !important;
    /* border: 1px solid #dfdfdf; */
    padding: 10px;
    padding-top: 20px;
    height: 600px;
    overflow: scroll;
  }

  .slick-dots {
    bottom: none;
    top: 35px;
    z-index: ${theme.zIndex.z_4};
    position: fixed;
    height: 20px;
  }

  .slick-arrow {
    position: fixed;
    display: block;
    background: #111;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0.05;
    transition: all 0.3s;
    z-index: ${theme.zIndex.z_4};

    &:hover {
      opacity: 0.5;
    }
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev {
    left: 10px;
  }
`;

interface HeadingCardInterface {
  _title: string;
  children: ReactNode;
}

const HeadingCard = ({ _title, children }: HeadingCardInterface) => {
  return (
    <CardTemplate>
      <Text size={18} bold>
        {_title}
      </Text>
      <section>{children}</section>
    </CardTemplate>
  );
};

export default HeadingCard;
