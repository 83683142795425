import styled, { css } from "styled-components";

import theme from "src/styles/theme";

export const SpanStyle = css`
  color: ${theme.color.black_00};
  font-weight: ${(props: { [key: string]: string }) => props._bold && "700"};
  font-weight: ${(props: { [key: string]: string }) => props._medium && "500"};
  text-align: ${(props: { [key: string]: string }) => props._center && "center"};
  font-size: inherit;
  line-height: 1;
  letter-spacing: -0.2px;
  transition: all 0.15s;
`;

export const DefaultSpanStyle = styled.span`
  ${SpanStyle}
`;

export const ButtonSpanStyle = styled.span`
  ${SpanStyle}
  color:#fff;
  font-weight: 500;
  font-size: 17px;
`;

export const WarningSpanStyle = styled.span`
  ${SpanStyle}
  color: ${theme.color.red_00};
  font-weight: 400;
  font-size: 12px;
`;
