import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

const patchStoreMemo = async (storeId: number, commentId: number, memo: string) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/admin/stores/${storeId}/comments/${commentId}`,
    data: {
      comment: memo,
    },
  });

  return data;
};

export const usePatchStoreMemo = (storeId: number) => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["patchStoreMemo", storeId],
    ({ commentId, memo }: { commentId: number; memo: string }) => patchStoreMemo(storeId, commentId, memo),
    {
      onSuccess: () => {
        console.log("onSuccess");
        addFlag({
          title: "메모가 수정 되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
        queryClient.invalidateQueries(["StoreDetail", storeId]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
