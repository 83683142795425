import styled from "styled-components";

import theme from "src/styles/theme";

export const Dropdown = styled.div`
  width: 100%;
  text-align: center;
  background: #ffffff;
  border: 1.5px solid #d9d9d9;
  border-radius: 5px;
  font-size: 15px;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
`;

export const DropdownInner = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  background: #ffffff;
  border: 1.5px solid #d9d9d9;
  border-radius: 5px;
  font-size: 14px;
  color: #555;
  text-align: start;
  top: 30px;
  z-index: ${theme.zIndex.z_5};

  max-height: 400px;
  overflow-y: scroll;
`;

export const DropdownButton = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 2px 10px;

  &:hover {
    background: #eee;
  }
`;
