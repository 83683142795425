import { Button, Header } from "@carbon/react";
import { themes } from "@carbon/themes";
import styled from "styled-components";

export const SettlementLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: scroll;
`;

export const SettlementSideNavWrapper = styled.div`
  display: flex;
  border-left: 1px solid #e0e0e0;
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  width: 300px;
`;

export const HeaderWrapper = styled(Header)`
  padding: 0 32px;
`;

export const HeaderButton = styled(Button).attrs({
  iconDescription: "header-button",
})`
  justify-content: center;
  align-items: center;
  color: ${themes.white.textPrimary};
`;

export const SettlementContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 20px 20px 10px 20px;
  // margin-right: 300px;
  overflow-x: auto;
  overflow-y: auto;
  // width: calc(100% - 300px);
  min-width: 820px;
`;
