import styled, { css } from "styled-components";

import theme from "src/styles/theme";

export const TextStyle = css`
  color: ${theme.color.black_00};
  font-weight: ${(props: { [key: string]: string }) => props._bold && "700"};
  font-weight: ${(props: { [key: string]: string }) => props._medium && "500"};
  text-align: ${(props: { [key: string]: string }) => props._center && "center"};
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: -0.2px;
  transition: all 0.15s;
`;

export const DefaultTextStyle = styled.p`
  ${TextStyle}
`;

export const TitleTextStyle = styled.h3`
  ${TextStyle}
  font-size: 25px;
  font-weight: 700;
`;

export const SubTitleTextStyle = styled.p`
  ${TextStyle}
  color:${theme.color.gray_30};
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.7px;
`;
