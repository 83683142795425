import { ComposedModal, DatePickerInput, ModalBody } from "@carbon/react";
import styled from "styled-components";

export const CustomModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const ModalWrapper = styled(ComposedModal)`
  .cds--modal-container {
    background-color: white;
    width: fit-content;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const CustomDatePickerInput = styled(DatePickerInput)``;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
