import React from "react";

import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import ErrorIcon from "@atlaskit/icon/glyph/error";

import { useGetStoreDetail } from "@/apis/stores/getStoreDetail";

import { StoreServiceStatusProps } from "@/types/storeType";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

interface CustomErrorData {
  error: {
    message: string;
  };
}

// AxiosError를 확장한 새로운 타입
export interface CustomAxiosError extends AxiosError {
  response?: AxiosError["response"] & {
    data: CustomErrorData;
  };
}

const patchServiceStatus = async (store_id: string, body: StoreServiceStatusProps) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/admin/stores/${store_id}/service-status`,
    data: body,
  });

  return data;
};

export const usePatchServiceStatus = (store_id: string, onClose: () => void, onConfirm: () => void) => {
  const { addFlag } = useFlag();
  const { refetch } = useGetStoreDetail(Number(store_id));
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["patchServiceStatus", store_id],
    (data: StoreServiceStatusProps) => patchServiceStatus(store_id, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        refetch();
        addFlag({
          title: "상태 값이 업데이트 되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
        queryClient.invalidateQueries(["StoreDetail"]);
        onConfirm();
      },
      onError: (error: CustomAxiosError) => {
        addFlag({
          title: error.response?.data?.error.message || "개발팀에 문의해주세요.",
          icon: React.createElement(ErrorIcon, {
            primaryColor: "#DE350B",
            secondaryColor: "#FFFFFF",
            label: "Error",
            size: "medium",
          }),
          appearance: "error",
        });
        queryClient.invalidateQueries(["StoreDetail"]);
        onClose();
      },
    },
  );

  return mutation;
};
