import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

const deleteStoreMemo = async (storeId: number, commentId: number) => {
  const { data } = await instance({
    method: "delete",
    url: `/v2/admin/stores/${storeId}/comments/${commentId}`,
  });

  return data;
};

export const useDeleteStoreMemo = (storeId: number) => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["DeleteStoreMemo", storeId],
    (commentId: number) => deleteStoreMemo(storeId, commentId),
    {
      onSuccess: () => {
        console.log("onSuccess");
        addFlag({
          title: "메모가 삭제 되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
        queryClient.invalidateQueries(["StoreDetail"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
