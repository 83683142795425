import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

interface DepositStoreType {
  id?: number | null;
  depositedAt: string;
  cardCompany: string;
  amountDeposit: number;
  trxType: "D" | "W";
}

const putDepositStore = async (storeId: number, body: DepositStoreType[]) => {
  const { data } = await instance({
    method: "put",
    url: `/v2/admin/deposits/store/${storeId}`,
    data: body,
  });

  return data;
};

export const usePutDepositStore = (storeId: number) => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["PutDepositStore", storeId],
    (data: DepositStoreType[]) => putDepositStore(storeId, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        addFlag({
          title: "입출금 내역이 수정 되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
        queryClient.invalidateQueries(["DepositDetail"]);
        queryClient.invalidateQueries(["DepositOverview"]);
        queryClient.invalidateQueries(["MismatchedWithdrawal"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
