import { Stack, Table, TableCell } from "@carbon/react";
import { themes } from "@carbon/themes";
import styled from "styled-components";

import Text from "@/components/elements/Text";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 16px;
`;

export const DateLink = styled.div`
  cursor: pointer;
  width: fit-content;
`;

export const TableWrapper = styled(Stack)`
  gap: 4px;
  margin-bottom: 16px;
`;

export const CustomTableCell = styled(TableCell)`
  font-weight: 600;
  font-size: 12px;
  background-color: ${({ isTitle }) => (isTitle ? themes.white.layerHover01 : "white")};
`;

export const DepositOverviewTable = styled(Table)`
  border-top: 2px solid black;
  border-bottom: 4px solid black;
`;

export const DepositTableTitle = styled(Text)``;
