import React from "react";

import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import ErrorIcon from "@atlaskit/icon/glyph/error";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

interface CustomErrorData {
  message: string;
}

// AxiosError를 확장한 새로운 타입
export interface CustomAxiosError extends AxiosError {
  response?: AxiosError["response"] & {
    data: CustomErrorData;
  };
}

const postCalculateOverUnder = async () => {
  const { data } = await instance({
    method: "post",
    url: "/v2/admin/tasks/calculate-over-under",
  });

  return data;
};

export const usePostCalculateOverUnder = () => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(["postCalculateOverUnder"], () => postCalculateOverUnder(), {
    onSuccess: () => {
      console.log("onSuccess");
      addFlag({
        title: "과/미수금 계산을 시작합니다.",
        icon: React.createElement(CheckCircleIcon, {
          primaryColor: "#22A06B",
          secondaryColor: "#FFFFFF",
          label: "Success",
          size: "medium",
        }),
        appearance: "success",
      });
      queryClient.invalidateQueries(["MismatchedWithdrawal"]);
    },
    onError: (error: CustomAxiosError) => {
      console.log("onError", error);
      addFlag({
        title: error.response?.data?.message || "개발팀에 문의해주세요.",
        icon: React.createElement(ErrorIcon, {
          primaryColor: "#DE350B",
          secondaryColor: "#FFFFFF",
          label: "Error",
          size: "medium",
        }),
        appearance: "error",
      });

      queryClient.invalidateQueries(["MismatchedWithdrawal"]);
    },
  });

  return mutation;
};
