import React from "react";

import { Box } from "@atlaskit/primitives";

import {
  DayDepositBox,
  EarlypayBox,
  ItemChartBox,
  MonthDepositBox,
  UserChartBox,
  YearDepositBox,
} from "src/components/module/home/index";

import { ColumnGrid, HeaderWrap, Wrap } from "./index.styled";

const Home = () => {
  return (
    <Box style={{ overflow: "scroll", height: "100vh", paddingBottom: "100px" }}>
      <HeaderWrap>
        <EarlypayBox />
      </HeaderWrap>
      <HeaderWrap style={{ margin: 0 }}>
        <ItemChartBox />
      </HeaderWrap>
      <Wrap>
        <ColumnGrid>
          <UserChartBox />
        </ColumnGrid>
        <ColumnGrid>
          <DayDepositBox />
        </ColumnGrid>
        <ColumnGrid>
          <MonthDepositBox />
        </ColumnGrid>
        <ColumnGrid>
          <YearDepositBox />
        </ColumnGrid>
      </Wrap>
    </Box>
  );
};

export default Home;
