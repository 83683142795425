import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

const postPuzzling = async () => {
  const { data } = await instance({
    method: "post",
    url: `/v2/admin/tasks/puzzling`,
  });

  return data;
};

export const usePostPuzzling = () => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(["postPuzzling"], () => postPuzzling(), {
    onSuccess: () => {
      console.log("onSuccess");
      addFlag({
        title: "실회수금 불일치 가게 입금내역을 비교 중 입니다.",
        icon: React.createElement(CheckCircleIcon, {
          primaryColor: "#22A06B",
          secondaryColor: "#FFFFFF",
          label: "Success",
          size: "medium",
        }),
        appearance: "success",
      });
      queryClient.invalidateQueries(["GetPuzzling"]);
      queryClient.invalidateQueries(["MismatchedWithdrawal"]);
    },
    onError: error => {
      console.log("onError", error);
    },
  });

  return mutation;
};
