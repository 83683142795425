import DatePicker from "react-datepicker";
import styled from "styled-components";

// component(base)
import { FlexBox } from "@/components/atoms";

import theme from "src/styles/theme";

export const HeaderFilter = styled(FlexBox)`
  width: auto;
  .react-datepicker-wrapper {
    width: auto !important;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  border: 1px solid #ddd;
  width: 95px;
  padding: 2px 4px;
  border-radius: 4px;
  color: #333;

  z-index: ${theme.zIndex.z_5};
`;
