import React, { ReactNode, createContext, useContext, useState } from "react";

import { AutoDismissFlag, FlagGroup, FlagProps } from "@atlaskit/flag";

interface CustomFlagProps extends Omit<FlagProps, "id"> {
  id: number;
}

interface FlagContextType {
  addFlag: (flagProps: Omit<CustomFlagProps, "id">) => void;
}

const FlagContext = createContext<FlagContextType | undefined>(undefined);

interface FlagProviderProps {
  children: ReactNode;
}

export const FlagProvider: React.FC<FlagProviderProps> = ({ children }) => {
  const [flags, setFlags] = useState<CustomFlagProps[]>([]);

  const addFlag = (flagProps: Omit<CustomFlagProps, "id">) => {
    const newFlag: CustomFlagProps = { ...flagProps, id: Date.now() };
    setFlags(prevFlags => [newFlag, ...prevFlags]);
  };

  const removeFlag = (id: number) => {
    setFlags(prevFlags => prevFlags.filter(flag => flag.id !== id));
  };

  return (
    <FlagContext.Provider value={{ addFlag }}>
      {children}
      <FlagGroup>
        {flags.map(flag => (
          <AutoDismissFlag key={flag.id} {...flag} onDismissed={() => removeFlag(flag.id)} />
        ))}
      </FlagGroup>
    </FlagContext.Provider>
  );
};

// 커스텀 훅: 어디서든 addFlag를 사용할 수 있도록 함
export const useFlag = (): FlagContextType => {
  const context = useContext(FlagContext);
  if (!context) {
    throw new Error("useFlag must be used within a FlagProvider");
  }
  return context;
};
