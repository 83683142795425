import React, { useRef, useState } from "react";

import { Dropdown, DropdownButton, DropdownInner } from "./index.styled";

interface ChartCardInterface {
  _dropDownArr: { key: string; act: boolean; name?: string }[];
  _onClick: (arr: { key: string; act: boolean }[]) => void;
}

const DropDwon = ({ _onClick, _dropDownArr }: ChartCardInterface) => {
  const [drop, setDrop] = useState(false);
  const ref = useRef();

  const changeChart = (key: string) => {
    const arr = _dropDownArr.map(x => {
      if (x.key === key) {
        x.act = true;
        return x;
      } else {
        x.act = false;
        return x;
      }
    });

    setDrop(false);
    _onClick(arr);
  };

  const defaultName = _dropDownArr.filter(x => {
    if (x.act === true) return x;
  })[0]?.key;

  return (
    <>
      <div style={{ position: "relative" }}>
        <Dropdown onClick={() => setDrop(!drop)}>
          {defaultName}{" "}
          <img
            src="/arrow.png"
            width={10}
            style={{
              transform: `rotate(${drop ? 0 : 180}deg)`,
              marginLeft: "10px",
            }}
          />
        </Dropdown>
        {drop && (
          <DropdownInner ref={ref}>
            {_dropDownArr.map(x => {
              return (
                <DropdownButton key={x.key} onClick={() => changeChart(x.key)}>
                  {x.key}
                </DropdownButton>
              );
            })}
          </DropdownInner>
        )}
      </div>
    </>
  );
};

export default DropDwon;
