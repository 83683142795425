// style
import styled, { css } from "styled-components";

import theme from "src/styles/theme";

export const GridStyle = css`
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: { [key: string]: string }) => props._justify && "center"};
  align-items: ${(props: { [key: string]: string }) => props._align && "center"};
  position: ${(props: { [key: string]: string }) => props._relative && "relative"};
  overflow: ${(props: { [key: string]: string }) =>
    props._hidden || props._scroll ? (props._scroll ? "auto" : "hidden") : null};
`;

export const DefaultGridStyle = styled.div`
  ${GridStyle};
`;

export const RowGridStyle = styled.div`
  ${GridStyle};
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ModalGridStyle = styled.div`
  ${GridStyle};
  max-width: 560px;
  min-height: 35%;
  max-height: 60%;
`;

export const TopGridStyle = styled.div`
  ${GridStyle};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const BottomGridStyle = styled.div`
  ${GridStyle};
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1000px;
  z-index: ${theme.zIndex.z_1};
`;
