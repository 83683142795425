import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useGetStoreDetail } from "@/apis/stores/getStoreDetail";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

const patchPlatformStatus = async (storeId: number, accountId: number, status: string) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/admin/stores/${storeId}/platform-accounts/${accountId}`,
    data: {
      status,
    },
  });

  return data;
};

export const usePatchPlatformStatus = (storeId: number) => {
  const { addFlag } = useFlag();
  const { refetch } = useGetStoreDetail(Number(storeId));
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["patchServiceStatus", storeId],
    ({ accountId, status }: { accountId: number; status: string }) => patchPlatformStatus(storeId, accountId, status),
    {
      onSuccess: () => {
        console.log("onSuccess");
        refetch();
        addFlag({
          title: "계정 정보 상태 수정이 완료되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
        queryClient.invalidateQueries(["StoreDetail"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
