import { Toggle } from "@carbon/react";
import { Button, OverflowMenu, Select } from "@carbon/react";
import { themes } from "@carbon/themes";
import styled from "styled-components";

export const StoreInfoBoxWrapper = styled.div`
  // border: 1px solid ${themes.white.borderSubtle00};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const MenuButton = styled(OverflowMenu)`
  border: solid 1px ${themes.white.linkPrimary};
  stroke: ${themes.white.linkPrimary};

  .cds--overflow-menu-options::after {
    background-color: transparent;
  }
`;

export const StoreInfoContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
`;

export const StoreInfoSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  min-width: 35%;
  border-right: 1px solid ${themes.white.borderSubtle00};
  gap: 15px;
`;

export const SelectWrapper = styled(Select)`
  width: 200px;
  min-width: 200px;
`;

export const IntegratedSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 32px;
  width: 65%;
  min-width: 65%;
`;

export const UnderDepositDetailButton = styled(Button).attrs({
  iconDescription: "underDeposit-detail",
})`
  padding: 7px 16px;
`;

export const ChangeButton = styled(Button).attrs({
  iconDescription: "change-button",
})`
  position: absolute;
  top: 0;
  right: 0;
`;

export const SaveButton = styled(Button).attrs({
  iconDescription: "save-button",
})`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: ${({ disabled }) => (disabled ? themes.white.buttonDisabled : "white")} !important;
  color: ${({ disabled }) => (disabled ? themes.white.textOnColorDisabled : themes.white.linkPrimary)} !important;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? themes.white.buttonDisabled : themes.white.linkPrimary} !important;
    color: ${({ disabled }) => (disabled ? themes.white.textOnColorDisabled : "white")} !important;
  }
  &:active {
    background-color: ${({ disabled }) =>
      disabled ? themes.white.buttonDisabled : themes.white.linkPrimary} !important;
    color: ${({ disabled }) => (disabled ? themes.white.textOnColorDisabled : "white")} !important;
  }
`;

export const UnderDepositStack = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CopiedAccountWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ConnectSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleWrapper = styled.div`
  display: flex;
`;

export const CustomToggle = styled(Toggle)`
  width: 100px;

  .cds--toggle__text {
    color: ${({ isConnected }) => (isConnected ? themes.white.linkPrimary : themes.white.textError)};
  }
`;
