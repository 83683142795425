import React, { ReactNode, useRef, useState } from "react";

// component(styled)
import { AccordionOutBox, ToggleInner, ToggleTitle } from "./index.styled";

interface AccordionType {
  children: ReactNode[];
  _style?: object;
  _custom?: boolean;
  _onClick?: (state: boolean) => void;
}

const Accordion = ({ children, _style, _custom = false, _onClick = () => {} }: AccordionType) => {
  // Accordion height state
  const [accordion, setAccordion] = useState(false);

  // DOM control ref
  const outBox = useRef<HTMLDivElement>(null);
  const inBox = useRef<HTMLDivElement>(null);

  // button toggle action Fn
  const toggleButton = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (outBox === null || inBox === null) return;

    if (outBox?.current) {
      if (outBox.current.clientHeight > 0) {
        outBox.current.style.height = "0";
      } else {
        outBox.current.style.height = `${inBox?.current && inBox.current.clientHeight}px`;
      }
    }

    setAccordion(!accordion);
    _onClick(!accordion);
  };

  return (
    <AccordionOutBox style={{ ..._style }}>
      <ToggleTitle onClick={toggleButton} _custom={_custom}>
        <div>{children[0]}</div>
      </ToggleTitle>
      <ToggleInner ref={outBox}>
        <div ref={inBox}>{children[1]}</div>
      </ToggleInner>
    </AccordionOutBox>
  );
};

export default Accordion;
