import React, { useRef, useState } from "react";

import styled from "@emotion/styled";
import { useInfiniteQuery } from "react-query";
import { useLocation } from "react-router";

import Button from "@atlaskit/button/new";

import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";

import DynamicTable from "@atlaskit/dynamic-table";
import EmptyState from "@atlaskit/empty-state";
import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";
import { Box } from "@atlaskit/primitives";

import { useSearchStore } from "@/hooks/useSearchStore";

import { get_store_list } from "@/apis/stores/getStoreList";

import NoMatchingImage from "@/assets/images/no-matching.png";

import Text from "@/components/elements/Text";
import SearchStoreBox from "@/components/features/settlement/SearchStoreBox";
import ActualAmountModal from "@/components/features/settlement/StoreInfoBox/ActualCollectionModal";

import { Wrapper } from "./index.styled";

const SERVICE_STATUS_TYPE: Record<string, { appearance: string; color?: string; backgroundColor?: string }> = {
  일시정지: { appearance: "default" },
  사용중: { appearance: "success" },
  "해지 진행": { appearance: "removed" },
  "일시정지 예정": { appearance: "new" },
  폐업정지: { appearance: "moved" },
  "폐업 해지": { appearance: "default", color: "#206A83", backgroundColor: "#E7F9FF" },
  "일반 해지": { appearance: "default", color: "#943D73", backgroundColor: "#FFECF8" },
  "매출 미발생 일시정지 예정": { appearance: "inprogress" },
};

const Store: React.FC = () => {
  const [openMismatched, setOpenMismatched] = useState<boolean>(false);
  const [checkedCfa, setCheckedCfa] = useState<string[]>([]);

  const location = useLocation();
  const headers = ["가게이름", "고객이름", "사업자등록번호", "서비스 이용 상태"];
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get("search");
  const { handleGoToDetail } = useSearchStore();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleCloseModal = () => {
    setOpenMismatched(false);
  };

  /** 무한 스크롤 구현을 통해 데이터를 일정 개수씩 가져오는 함수 */
  const { data } = useInfiniteQuery(
    ["storeList", searchKeyword],
    ({ pageParam = 1 }) => get_store_list(pageParam, 20, searchKeyword),
    {
      select: data => ({
        pages: searchKeyword ? data.pages.flatMap(list => list?.results) : undefined,
        pageParams: searchKeyword ? data?.pageParams : undefined,
      }),
      getNextPageParam: lastPage => {
        // 마지막 페이지일 경우 페이지 업데이트 막기
        const nextPage = lastPage?.nextPage;
        return nextPage ? nextPage : undefined;
      },
    },
  );

  /** target observer 요소를 감지하여 storeList 의 다음 페이지 업데이트 */
  // const { setTarget } = useInfiniteScroll({
  //   hasNextPage,
  //   fetchNextPage,
  // });

  const header = {
    cells: headers.map(header => ({
      key: header,
      content: header,
      style: {
        fontSize: "12px",
        padding: "7px 8px",
        color: "#44546F",
        fontWeight: "bold",
      },
    })),
  };

  const rows = data?.pages?.map((store, index) => {
    const storeName = store?.title;
    const userName = store?.user?.name;
    const businessNumber = store?.businessRegistrationNumber?.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");

    return {
      key: store.id,
      title: `${storeName}_${index}`,
      cells: [
        {
          content: (
            <NameWrapper onClick={() => handleGoToDetail(store.id, storeName)}>
              {storeName ? storeName : "-"}
            </NameWrapper>
          ),
        },
        {
          content: userName ?? "-",
          style: {
            fontSize: "14px",
            color: "#172B4D",
          },
        },
        {
          content: businessNumber ?? "-",
          style: {
            fontSize: "14px",
            color: "#172B4D",
          },
        },
        {
          content: (
            <Lozenge
              testId="serviceStatus"
              appearance={SERVICE_STATUS_TYPE[store.serviceStatus].appearance as ThemeAppearance}
              style={{
                color: SERVICE_STATUS_TYPE[store.serviceStatus].color,
                backgroundColor: SERVICE_STATUS_TYPE[store.serviceStatus].backgroundColor,
              }}
            >
              {store.serviceStatus}
            </Lozenge>
          ),
        },
      ],
    };
  });

  return (
    <Wrapper>
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Text size={24} bold>
          정산 조정
        </Text>
        <Button
          ref={buttonRef}
          onClick={event => {
            event.stopPropagation();
            setOpenMismatched(prev => !prev);
          }}
          iconAfter={ChevronDownIcon}
          appearance="primary"
        >
          실회수금 불일치 가게
        </Button>
        {openMismatched && (
          <ActualAmountModal
            buttonRef={buttonRef}
            onClose={handleCloseModal}
            checkedCfa={checkedCfa}
            setCheckedCfa={setCheckedCfa}
          />
        )}
      </Box>

      <SearchStoreBox placeholder="가게이름, 고객이름, 사업자로 검색" hasSearchButton isNeedPadding topSpacing={128} />
      <DynamicTable
        head={header}
        rows={rows}
        rowsPerPage={10}
        defaultPage={1}
        loadingSpinnerSize="large"
        defaultSortKey="가게이름"
        defaultSortOrder="ASC"
      />
      {searchKeyword && data?.pages?.length === 0 && (
        <EmptyState header="일치하는 가게 정보가 없습니다." imageUrl={NoMatchingImage} />
      )}
      {!searchKeyword && (
        <Box style={{ textAlign: "center", paddingTop: "86px", fontSize: "14px", color: "#172B4D" }}>
          가게를 검색해주세요.
        </Box>
      )}

      {/** 정산 가게 목록 */}
      {/* <TableWrapper>
        <StoreTable aria-label="store table" stickyHeader={true} size="md" useZebraStyles={true}>
          <TableHead>
            <TableRow>
              {headers.map((header: string, index: number) => (
                <TableHeader key={index}>{header}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.pages?.map((props, index: number) => {
              const storeName = props?.title;
              const userName = props.user?.name;
              const businessNumber = props.businessRegistrationNumber?.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");

              return (
                <TableRow key={index}>
                  <TableCell>
                    <LinkText onClick={() => handleGoToDetail(props.id, storeName)}>
                      {storeName ? storeName : "-"}
                    </LinkText>
                  </TableCell>
                  <TableCell>{userName ? userName : "-"}</TableCell>
                  <TableCell>{businessNumber ? businessNumber : "-"}</TableCell>
                </TableRow>
              );
            })}
            {data?.pages && <div ref={setTarget} style={{ height: 1, width: "100%" }} />}
          </TableBody>
        </StoreTable>
      </TableWrapper> */}
    </Wrapper>
  );
};

export default Store;

const NameWrapper = styled.div`
  font-size: 14px;
  color: #0045d6;
  cursor: pointer;
  padding: 10px 8px;
  &:hover {
    text-decoration: underline;
  }
`;
