import React from "react";

import { useRecoilValue } from "recoil";

import { IconProp } from "@atlaskit/button/dist/types/new-button/variants/types";

import Banner from "@atlaskit/banner";
import { Box, xcss } from "@atlaskit/primitives";

import { bannerState } from "@/recoil/banner/atoms";

export interface BannerProps {
  appearance: "error" | "announcement" | "warning";
  message: string;
  icon?: React.ReactElement<IconProp, string | React.JSXElementConstructor<IconProp>>;
}

/**
 * 상단 배너 컴포넌트
example : 
  setBanner({
    visible: true,
    appearance: "error",
    message: "An error has occurred!",
    icon: null,
  });
 }
 */

const TopBanner = () => {
  const { visible, appearance, message, icon } = useRecoilValue(bannerState);

  if (!visible) return null;

  return (
    <Box xcss={BannerWrapper}>
      <Banner appearance={appearance} icon={icon}>
        {message}
      </Banner>
    </Box>
  );
};

export default TopBanner;

const BannerWrapper = xcss({
  zIndex: "modal",
  width: "100%",
});
