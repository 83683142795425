/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import styled from "styled-components";

import { get_users_cohort } from "src/apis/chart";
import { ChartCard } from "src/components/block";

const CohortGrid = styled.div`
  display: grid;
`;

const Cohort = styled.div`
  padding: 5px;
  border: 1px solid #cdcdcd;
  text-align: center;
  font-weight: 500;
  border-collapse: collapse;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CohortBox = () => {
  const [cohort, setCohort] = useState({});
  const [date, setDate] = useState<any>({
    start: new Date("2023-01-01"),
    end: new Date(),
  });

  const getUsersCohort = async () => {
    const { data } = await get_users_cohort(
      date.start.toISOString().split("T")[0],
      date.end.toISOString().split("T")[0],
    );

    setCohort(data);
  };

  useEffect(() => {
    getUsersCohort();
  }, [date]);

  const cohortDateKeyList: string[] = Object.keys(cohort);
  const cohortValueList: { [key: string]: number }[] = Object.values(cohort);

  const afterMonth = cohortDateKeyList.map((row, idx) => {
    return `M+${idx + 1}`;
  });

  const rows = cohortDateKeyList.map((row, rowIndex) => {
    let retentionUser = 0;
    const earlypay = cohortValueList[rowIndex].earlypay;
    const values = Object.values(cohortValueList[rowIndex]).map((count, countIndex) => {
      if (countIndex === 0) return count;
      retentionUser = retentionUser + count;
      const terminationRate = ((earlypay - retentionUser) / earlypay) * 100;

      return `${earlypay - retentionUser}명 (${terminationRate.toFixed(0)}%)`;
    });
    return [row, ...values];
  });

  const csvData = [["날짜", "유저수", ...afterMonth], ...rows];

  return (
    <ChartCard
      _dropdown={false}
      _title="월별 리텐션 분석"
      _date={true}
      _dateObj={date}
      _setDateObj={setDate}
      _month={true}
    >
      <div style={{ margin: "auto 0", overflow: "hidden" }}>
        <div style={{ textAlign: "end", marginBottom: "10px" }}>
          <CSVLink data={csvData}>CSV 파일받기</CSVLink>
        </div>
        <h6 style={{ marginBottom: "40px" }}>리텐션 : 월 총 해지수 / 해당 월 승인수</h6>
        <CohortGrid
          style={{
            gridTemplateColumns: "90px 70px " + "1fr ".repeat(cohortDateKeyList.length),
          }}
        >
          <Cohort>날짜</Cohort>
          <Cohort>유저수</Cohort>
          {cohortDateKeyList.map((date, index) => {
            return <Cohort key={date}>M+{index + 1}</Cohort>;
          })}
        </CohortGrid>
        {cohortDateKeyList.map((date, index) => {
          const year = date.split("-")[0];
          const month = date.split("-")[1];
          const valueList = Object.values(cohortValueList[index]);

          const totalUser = valueList[0];
          let terminatedUser = 0;

          return (
            <CohortGrid
              style={{
                gridTemplateColumns: "90px 70px " + "1fr ".repeat(cohortDateKeyList.length),
              }}
              key={date}
            >
              <Cohort>{`${year}.${month}`}</Cohort>
              {valueList.map((user, idx) => {
                const fristCohort = idx === 0;
                if (!fristCohort) {
                  terminatedUser = terminatedUser + user;
                }
                const retentionUser = totalUser - terminatedUser;
                const terminationRate = (retentionUser / totalUser) * 100;

                return (
                  <div key={`${valueList.length}-${idx}`}>
                    {fristCohort ? (
                      <Cohort key={`${valueList.length}-${idx}`}>{user}명</Cohort>
                    ) : (
                      <Cohort
                        key={`${valueList.length}-${idx}`}
                        style={{
                          background: "#ffffff",
                          filter: `brightness(${terminationRate}%)`,
                        }}
                      >
                        {retentionUser}명 <span style={{ fontSize: "14px" }}>({terminationRate.toFixed(0)}%)</span>
                      </Cohort>
                    )}
                  </div>
                );
              })}
            </CohortGrid>
          );
        })}
      </div>
    </ChartCard>
  );
};

export default CohortBox;
