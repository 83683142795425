import React, { useEffect, useRef, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router";

import Button from "@atlaskit/button/new";

import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";

import DropdownMenu, { DropdownItemCheckbox, DropdownItemCheckboxGroup } from "@atlaskit/dropdown-menu";
import Pagination from "@atlaskit/pagination";
import { Box } from "@atlaskit/primitives";
import Spinner from "@atlaskit/spinner";

import { useGetCfaAccounts } from "@/apis/stores/getCfaAccounts";
import { useGetMismatchedWithdrawal } from "@/apis/stores/getMismatchedWithdrawals";
import { useGetPuzzling } from "@/apis/stores/getPuzzling";
import { usePostCalculateOverUnder } from "@/apis/stores/postCalculateOverUnder";
import { usePostPuzzling } from "@/apis/stores/postPuzzling";

import { getDate } from "@/utils/getFormattedDate";

const ActualAmountModal = ({
  buttonRef,
  onClose,
  checkedCfa,
  setCheckedCfa,
}: {
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  onClose: () => void;
  checkedCfa: string[];
  setCheckedCfa: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const navigate = useNavigate();
  const [openStores, setOpenStores] = useState<string[]>([]);
  const [currentOpenStore, setCurrentOpenStore] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openCfaDropdown, setOpenCfaDropdown] = useState<boolean>(false);
  const [isClickPuzzling, setIsClickPuzzling] = useState<boolean>(false);

  const today = getDate("now", "yyyy-mm-dd");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get("start_date") ?? today;
  const endDate = searchParams.get("end_date") ?? today;

  const modalRef = useRef<HTMLDivElement | null>(null);

  const toggle = (name: string) => {
    setCheckedCfa(
      prev =>
        prev.includes(name)
          ? prev.filter(item => item !== name) // 값이 있으면 제거
          : [...prev, name], // 값이 없으면 추가
    );
  };
  const checkedCfaString = checkedCfa.join(", ");
  const selectedCount = checkedCfa.length;

  const toggleStore = (storeId: string) => {
    setCurrentOpenStore(storeId);
    setOpenStores(prev => (prev.includes(storeId) ? prev.filter(id => id !== storeId) : [...prev, storeId]));
  };

  const {
    data,
    isLoading: misMatchedLoading,
    refetch,
  } = useGetMismatchedWithdrawal({ page: currentPage, cfaAccounts: checkedCfaString });
  const { data: cfaAccounts, isLoading: cfaLoading } = useGetCfaAccounts();
  const { data: puzzlingStatus, refetch: puzzlingStatusRefetch, isLoading: puzzlingStatusLoading } = useGetPuzzling();
  const mutate = usePostPuzzling();
  const { mutate: postCalculateOverUnder } = usePostCalculateOverUnder();

  const handlePuzzling = () => {
    setIsClickPuzzling(true);
    mutate.mutateAsync();
    puzzlingStatusRefetch();
  };

  const handleReload = () => {
    refetch();
    puzzlingStatusRefetch();
  };

  const handleCalculateOverUnder = () => {
    postCalculateOverUnder();
    refetch();
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      !(buttonRef.current && buttonRef.current.contains(event.target as Node))
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setOpenCfaDropdown(false);
  }, [data]);

  useEffect(() => {
    if (isClickPuzzling) {
      const intervalId = setInterval(() => {
        puzzlingStatusRefetch();
      }, 3000);
      return () => clearInterval(intervalId);
    } else {
      refetch();
      setIsClickPuzzling(false);
    }
  }, [isClickPuzzling]);

  useEffect(() => {
    if (puzzlingStatus?.status === "IN_PROGRESS") {
      setIsClickPuzzling(true);
    } else {
      setIsClickPuzzling(false);
    }
  }, [puzzlingStatus]);

  useEffect(() => {
    if (data?.calculateUnderOver === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        refetch();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [data]);

  if (misMatchedLoading || cfaLoading || puzzlingStatusLoading)
    return (
      <Box
        style={{
          position: "absolute",
          right: 20,
          top: 64,
          zIndex: 999,
          backgroundColor: "white",
          borderRadius: "3px",
          boxShadow: "0px 8px 12px 0px #091E4226, 0px 0px 1px 0px #091E424F",
        }}
      >
        <Box
          style={{
            width: "433px",
            paddingLeft: "20px",
            paddingRight: "20px",
            position: "relative",
          }}
        >
          <Box style={{ padding: "16px 0" }}>
            <Box style={{ color: "#172B4D", fontSize: "20px", fontWeight: "bold" }}>{`실회수금 불일치 가게`}</Box>
            <Box style={{ display: "flex", gap: "4px", paddingTop: "12px" }}>
              <Skeleton width={146} height={37} />
              <Skeleton width={116} height={37} />
            </Box>
          </Box>
          <Box style={{ paddingBottom: "8px", display: "flex", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", gap: "4px" }}>
              <Skeleton width={104} height={28} />
              <Skeleton width={88} height={28} />
            </Box>
            <Skeleton width={96} height={28} />
          </Box>
          <Skeleton width={393} height={420} style={{ marginBottom: "60px" }} />
          <Skeleton width={393} height={36} style={{ marginBottom: "12px", marginTop: "8px" }} />
        </Box>
      </Box>
    );

  return (
    <Box
      ref={modalRef}
      style={{
        position: "absolute",
        right: 20,
        top: 64,
        zIndex: 999,
        backgroundColor: "white",
        borderRadius: "3px",
        boxShadow: "0px 8px 12px 0px #091E4226, 0px 0px 1px 0px #091E424F",
      }}
    >
      <Box
        style={{
          width: "433px",
          paddingLeft: "20px",
          paddingRight: "20px",
          position: "relative",
        }}
      >
        <Box style={{ padding: "16px 0" }}>
          <Box style={{ color: "#172B4D", fontSize: "20px", fontWeight: "bold" }}>{`실회수금 불일치 가게 (${
            isClickPuzzling ? "검토 중" : data.totalCount
          })`}</Box>
          <Box style={{ display: "flex", gap: "4px", paddingTop: "12px" }}>
            <Button
              isDisabled={data.fetchWithdrawals !== "DONE" || puzzlingStatus.status === "IN_PROGRESS"}
              onClick={handlePuzzling}
            >
              {isClickPuzzling ? <Spinner size="small" label="Loading" /> : "실제 출금 내역 검토"}
            </Button>
          </Box>
        </Box>

        <Box style={{ paddingBottom: "8px", display: "flex", justifyContent: "space-between" }}>
          <Box>
            <DropdownMenu
              trigger={({ triggerRef, ...props }) => (
                <Button {...props} ref={triggerRef} appearance="subtle" spacing="compact" iconAfter={ChevronDownIcon}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <Box style={{ fontSize: "14px", color: openCfaDropdown ? "#FFFFFF" : "#172B4D" }}>CFA</Box>
                    {selectedCount > 0 && (
                      <Box
                        style={{
                          padding: "4px",
                          backgroundColor: "#0045D6",
                          borderRadius: "50%",
                          color: "white",
                          width: "18px",
                          height: "18px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {selectedCount}
                      </Box>
                    )}
                  </Box>
                </Button>
              )}
              isOpen={openCfaDropdown}
              onOpenChange={() => setOpenCfaDropdown(prev => !prev)}
              shouldRenderToParent
              spacing="compact"
            >
              <DropdownItemCheckboxGroup id="actions">
                {cfaAccounts.map((data, index) => (
                  <DropdownItemCheckbox
                    key={`${data.identification}_${index}`}
                    id={`${data.identification}`}
                    onClick={() => {
                      toggle(data.identification);
                      setOpenCfaDropdown(prev => !prev);
                    }}
                    isSelected={checkedCfa.includes(data.identification)}
                  >
                    {data.identification}
                  </DropdownItemCheckbox>
                ))}
              </DropdownItemCheckboxGroup>
            </DropdownMenu>
            <Button appearance="subtle" spacing="compact" onClick={() => setCheckedCfa([])}>
              <Box style={{ fontSize: "14px", color: "#172B4D" }}>필터 지우기</Box>
            </Button>
          </Box>

          <Button iconBefore={RefreshIcon} appearance="subtle" spacing="compact" onClick={handleReload}>
            <Box style={{ fontSize: "14px", color: "#172B4D" }}>새로고침</Box>
          </Button>
        </Box>

        {data.fetchWithdrawals !== "DONE" && puzzlingStatus.status !== "IN_PROGRESS" && (
          <Box style={{ color: "#172B4D", fontSize: "14px", textAlign: "center", padding: "48px 0" }}>
            아직 회수금 가져오기가 진행되지 않아
            <br />
            실제 출금 내역 검토를 진행할 수 없습니다.
          </Box>
        )}

        {data.fetchWithdrawals === "DONE" &&
          data.results.length === 0 &&
          puzzlingStatus.status !== "IN_PROGRESS" &&
          checkedCfa.length === 0 && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box style={{ color: "#172B4D", fontSize: "14px", textAlign: "center", padding: "48px 0 12px 0" }}>
                실제 출금 내역 검토를 완료하였습니다.
                <br />
                과/미수금 계산을 진행해주세요.
              </Box>
              <Button
                appearance="primary"
                isDisabled={data?.calculateUnderOver === "IN_PROGRESS"}
                onClick={handleCalculateOverUnder}
              >
                <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {data?.calculateUnderOver === "IN_PROGRESS" && (
                    <Spinner interactionName="load" label="Loading" size="small" />
                  )}
                  {data?.calculateUnderOver === "IN_PROGRESS" ? "과/미수금 계산 중" : "과/미수금 계산"}
                </Box>
              </Button>
            </Box>
          )}

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            height: "482px",
            overflowY: "scroll",
            padding: "2px 1px 60px 1px",
          }}
        >
          {data.results?.map((data, index) => (
            <Box
              key={index}
              style={{
                padding: "6px 8px",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 1px 1px 0px #091E4240, 0px 0px 1px 0px #091E424F",
                borderRadius: "3px",
                backgroundColor:
                  openStores.includes(data.title) && currentOpenStore === data.title ? "#EEF3FF" : "white",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => toggleStore(data.title)}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ChevronDownIcon label="" />
                  <Box
                    style={{
                      color: "#172B4D",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.title} · {data.username}
                  </Box>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", height: "24px" }}>
                  <Button spacing="compact">
                    <Box
                      style={{
                        color: "#172B4D",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      onClick={event => {
                        event.stopPropagation();
                        onClose();
                        navigate(
                          `/settlement/detail/${data.id}?search=${data.title}&start_date=${startDate}&end_date=${endDate}`,
                        );
                      }}
                    >
                      이동
                    </Box>
                  </Button>
                </Box>
              </Box>
              {openStores.includes(data.title.toString()) && (
                <Box
                  style={{
                    padding: "10px 4px 6px 4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ color: "#172B4D", fontSize: "14px", fontWeight: 500 }}>회수추산액</Box>
                    <Box style={{ color: "#172B4D", fontSize: "14px", fontWeight: 500 }}>
                      {data.expectedWithdrawalAmount.toLocaleString("ko-KR")}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ color: "#172B4D", fontSize: "14px", fontWeight: 500 }}>실회수액</Box>
                    <Box style={{ color: "#172B4D", fontSize: "14px", fontWeight: 500 }}>
                      {data.actualWithdrawalAmount.toLocaleString("ko-KR")}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ color: "#172B4D", fontSize: "14px", fontWeight: 500 }}>차액</Box>
                    <Box
                      style={{
                        color: data.differenceAmount > 0 ? "#0045D6" : "#AE2E24",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {data.differenceAmount.toLocaleString("ko-KR")}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>

        {data.totalCount > 0 && (
          <Box
            style={{
              bottom: 0,
              left: 0,
              width: "100%",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              padding: "8px 0 12px",
            }}
          >
            <Pagination
              nextLabel="Next"
              label="Page"
              pageLabel="Page"
              selectedIndex={currentPage - 1}
              pages={Array.from({ length: data.lastPage }, (_, i) => i + 1)}
              previousLabel="Previous"
              onChange={(_, page) => setCurrentPage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ActualAmountModal;
