/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";
import { showToast } from "@/components/elements/Toast";

import { instance } from "../instance";

// 오늘 날짜를 기준으로 남은 회수 예정액에 대한 회수 예정일을 변경합니다.
const post_expected_payback_date = async (store_id: number, body: { date: string }) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/admin/platform-sales/stores/${store_id}/deposit-date`,
    data: body,
  });

  return data;
};

export const useEditExpectedPaybackDate = (store_id: number) => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["EditExpectedPaybackDate", store_id],
    (data: { date: string }) => post_expected_payback_date(store_id, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        queryClient.invalidateQueries(["ExpectedPaybackDate"]);
        addFlag({
          title: "회수예정일자 업데이트 되었습니다.",
          icon: React.createElement(CheckCircleIcon, {
            primaryColor: "#22A06B",
            secondaryColor: "#FFFFFF",
            label: "Success",
            size: "medium",
          }),
          appearance: "success",
        });
      },
      onError: (error: any) => {
        const message = error?.response?.data?.error?.message;
        console.log("onError", error?.response?.data?.error?.message);
        showToast({
          kind: "error",
          title: message ? message : "회수예정일자 업데이트 실패",
        });
      },
    },
  );

  return mutation;
};
