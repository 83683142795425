import React, { useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "react-router";

import Button, { IconButton } from "@atlaskit/button/new";

import CopyIcon from "@atlaskit/icon/core/copy";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import EditIcon from "@atlaskit/icon/glyph/edit";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import MediaServicesDocumentIcon from "@atlaskit/icon/glyph/media-services/document";

import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import Lozenge from "@atlaskit/lozenge";
import { ThemeAppearance } from "@atlaskit/lozenge";
import { ButtonItem } from "@atlaskit/menu";
import { ModalTransition } from "@atlaskit/modal-dialog";
import { Box, xcss } from "@atlaskit/primitives";

import { useGetExpectedPaybackDate } from "@/apis/deposit/getExpectedPaybackDate";
import { useEditStoreDetail } from "@/apis/stores/patchStoreDetail";
import { usePatchPlatformStatus } from "@/apis/stores/patchStorePlatformStatus";

import { StoreDetailInfoProps, StoreDetailProps } from "@/types/storeType";

import { changeNumberToMoney } from "@/utils/changeNumberToMoney";

import { useFlag } from "@/components/block/Flag";
import ConnectedPlatformList from "@/components/features/settlement/StoreInfoBox/ConnectedPlatformList";
import EditExpectedPaybackDateModal from "@/components/features/settlementDetail/EditExpectedPaybackDateModal";

import ChangeStatusModal from "./ChangeStatusModal";
import MemoModal from "./MemoModal";
import UseStatusModal from "./UseStatusModal";

export interface StoreInfoBoxProps {
  items: StoreDetailProps;
}

const ORIGIN_SERVICE_STATUS = {
  A: "사용중",
  WPNS: "매출 미발생 일시정지 예정",
  WP: "일시정지 예정",
  P: "일시정지",
  PC: "폐업정지",
  TP: "해지 진행",
  TC: "폐업 해지",
  T: "일반 해지",
};

const SERVICE_STATUS_TYPE: Record<string, { appearance: string; color?: string; backgroundColor?: string }> = {
  P: { appearance: "default" },
  A: { appearance: "success" },
  TP: { appearance: "removed" },
  WP: { appearance: "new" },
  WPNS: { appearance: "inprogress" },
  PC: { appearance: "moved" },
  TC: { appearance: "default", color: "#206A83", backgroundColor: "#E7F9FF" },
  T: { appearance: "default", color: "#943D73", backgroundColor: "#FFECF8" },
};

const SERVICE_STATUS_TYPE_LOZENGE = [
  { id: "A", value: "사용중", appearance: "success", disabled: [] },
  { id: "WP", value: "일시정지 예정", appearance: "new", disabled: ["TP"] },
  { id: "P", value: "일시정지", appearance: "default", disabled: ["TC"] },
  {
    id: "PC",
    value: "폐업정지",
    appearance: "moved",
    disabled: ["A", "WP", "P", "TP", "T"],
  },
  { id: "TP", value: "해지진행", appearance: "removed", disabled: [] },
  {
    id: "TC",
    value: "폐업 해지",
    appearance: "default",
    disabled: ["A", "WP", "P", "PC", "TP", "T"],
    style: { color: "#206A83", backgroundColor: "#E7F9FF" },
  },
  {
    id: "T",
    value: "일반 해지",
    appearance: "default",
    disabled: ["A", "WP", "P", "PC", "TP", "TC"],
    style: { color: "#943D73", backgroundColor: "#FFECF8" },
  },
];

const REASON: Record<string, string> = {
  NORMAL: "일반",
  CLOSED: "폐업",
  "TRANSFERED OWNERSHIP": "양도/양수",
  UNSATISFIED: "서비스 불만",
  DORMANT: "휴면",
  FORCED: "강제",
  ETC: "기타",
};

const DELIVERY_STATUS: Record<string, { appearance: string; text: string }> = {
  VALID: { appearance: "success", text: "사용중" },
  INVALID: { appearance: "moved", text: "로그인 불가" },
  SUSPENDED: { appearance: "removed", text: "사용 정지" },
  DISABLED: { appearance: "default", text: "비활성화" },
};

const SALES_TYPE: Record<string, string> = {
  AL: "모두",
  CA: "카드사만",
  DE: "배달사만",
};

const StoreInfoBox: React.FC<StoreInfoBoxProps> = ({ items }) => {
  const { addFlag } = useFlag();
  const location = useLocation();
  const [openMemo, setOpenMemo] = useState<boolean>(false);
  const [currentServiceStatus, setCurrentServiceStatus] = useState<string>(items.service.status);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState<{ show: boolean; isEditInfo: boolean }>({
    show: false,
    isEditInfo: false,
  });
  const [isVisibleExpectedPaybackDate, setVisibleExpectedPaybackDate] = useState(false);
  const vanPlatformList = items.platforms.filter(
    item => item.platform !== "쿠팡이츠" && item.platform !== "요기요" && item.platform !== "배달의민족",
  );
  const deliveryPlatformList = items.platforms.filter(
    item => item.platform === "요기요" || item.platform === "배달의민족" || item.platform === "쿠팡이츠",
  );
  const canEditDate = items.service.status === "WP" || items.service.status === "P";
  const canEditReason =
    items.service.status === "WP" ||
    items.service.status === "P" ||
    items.service.status === "TP" ||
    items.service.status === "TC" ||
    items.service.status === "T";

  const currentLozenge = SERVICE_STATUS_TYPE_LOZENGE.find(lozenge => lozenge.id === items.service.status);

  // currentLozenge가 존재하면 그에 따라 disabled 목록을 제외한 나머지 메뉴를 필터링합니다.
  const filteredMenuList = SERVICE_STATUS_TYPE_LOZENGE.filter(
    lozenge =>
      currentLozenge && // currentLozenge가 존재하고,
      !currentLozenge.disabled.includes(lozenge.id) && // 해당 항목의 disabled 목록에 포함되지 않으며,
      lozenge.id !== items.service.status, // 현재 선택된 상태를 제외
  );

  // const modalRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       setShowServiceStatus(false);
  //     }
  //   };

  //   // 전역적으로 클릭 이벤트를 감지
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [modalRef]);

  const filteredDeliveryStatus = (currentStatus: "VALID" | "INVALID" | "SUSPENDED" | "DISABLED") => {
    return Object.entries(DELIVERY_STATUS).filter(([key]) => key !== currentStatus);
  };
  const mutatePlatformStatus = usePatchPlatformStatus(items.id);

  const handlePatchPlatformStatus = async (accountId: number, status: string) => {
    await mutatePlatformStatus.mutateAsync({ accountId, status });
    document.body.click();
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      serviceStatus: items.service.status,
      platforms: [...vanPlatformList, ...deliveryPlatformList],
    },
  });
  const { fields } = useFieldArray<StoreDetailInfoProps, "platforms", "id">({
    name: "platforms",
    control: control,
  });
  const serviceStatus = watch("serviceStatus");
  const platforms = watch("platforms");
  const isCloseService = serviceStatus === "TP";
  const isDisabled = (items.service.status === "TP" && isCloseService) || !isDirty;

  const mutation = useEditStoreDetail(items.id);
  // const earlypayAdminUrl = import.meta.env.VITE_EARLYPAY_ADMIN_URI;

  const store = items.title;
  const name = items.user.name;
  const businessNumber = items.businessRegistrationNumber?.replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3");

  const baminAccount = items.deliveryAccounts.find(data => data.platform === "BAEMIN");
  const yogiyoAccount = items.deliveryAccounts.find(data => data.platform === "YOGIYO");
  const coupangAccount = items.deliveryAccounts.find(data => data.platform === "COUPANG");

  const { data: depositDate } = useGetExpectedPaybackDate(items.id);

  /** 사용자 세부 정보 확장 버튼 이벤트 함수 */
  // const onClickExpandButton = () => {
  //   setIsSelectedExpand(!isSelectedExpand);
  // };

  /** 회수예정날짜 수정 모달 선택하였을 경우 */
  const handleClickExpectedPaybackDate = () => {
    setVisibleExpectedPaybackDate(true);
  };

  /** 회수예정날짜 수정 모달 닫기 호출 */
  const closeExpectedPaybackDateModal = () => {
    setVisibleExpectedPaybackDate(false);
  };

  /** 서비스 사용 상태 저장 */
  const onSubmit = useCallback(
    (data: StoreDetailInfoProps) => {
      mutation.mutate(data);
      addFlag({
        title: "가게정보가 업데이트 되었습니다.",
        icon: React.createElement(CheckCircleIcon, {
          primaryColor: "#22A06B",
          secondaryColor: "#FFFFFF",
          label: "Success",
          size: "medium",
        }),
        appearance: "normal",
      });
    },
    [mutation],
  );

  const copyToClipboard = (copyText: string, message: string) => {
    navigator.clipboard.writeText(copyText);
    addFlag({
      title: message,
      icon: React.createElement(CheckCircleIcon, {
        primaryColor: "#22A06B",
        secondaryColor: "#FFFFFF",
        label: "Success",
        size: "medium",
      }),
      appearance: "normal",
    });
  };

  const onClickSetServiceStatus = ({
    status = items.service.status,
    isEditInfo = false,
  }: {
    status: string;
    isEditInfo: boolean;
  }) => {
    setCurrentServiceStatus(status);
    setOpenChangeStatusModal({ show: true, isEditInfo });
  };

  useEffect(() => {
    setValue("serviceStatus", serviceStatus);

    /** 서비스 사용에서 해지 진행을 선택하는 경우, 연동카드사/배달플랫폼 비활성화 */
    if (isCloseService) {
      const newPlatforms = platforms.map(item => ({
        ...item,
        isDisabled: true,
      }));
      setValue("platforms", [...newPlatforms]);
    } else {
      setValue("platforms", [...platforms]);
    }

    /** 가게 정보 상태 저장 했을 경우, 가게 정보 및 isDirty 등의 formState 초기화 */
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [serviceStatus, setValue, isSubmitSuccessful]);

  /** 페이지 이동 시에도 값이 유지될 수 있도록 구현 */
  useEffect(() => {
    reset({
      serviceStatus: items.service.status,
      platforms: [...vanPlatformList, ...deliveryPlatformList],
    });
  }, [location]);

  // useEffect(() => {
  //   const pannelElement = document.querySelector('[data-testid="rightPannel"]');
  //   console.log(pannelElement);
  //   pannelElement.setAttribute("style", "z-index: 300; background-color: green; margin-top: 50px");
  // }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StoreInfoWrapper>
        <Box xcss={storeInfoLeft}>
          <Box xcss={storeInfoText}>{name}</Box>
          <Divider />
          <Box xcss={storeName}>
            <a
              href={`https://admin.earlypay.co.kr/admin/stores/store/${items.id}/change/`}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#172B4D",
              }}
            >
              {store}
            </a>
          </Box>
          <IconButton
            icon={CopyIcon}
            spacing="compact"
            label="Notification"
            appearance="subtle"
            onClick={() => copyToClipboard(store, "가게 이름을 복사하였습니다.")}
          />
          <Divider />
          <Box xcss={storeInfoText}>{businessNumber}</Box>
          <Divider />
          <Box xcss={storeInfoText}>{items.user.mobileCarrier}</Box>
          <Box xcss={storeInfoText} style={{ paddingLeft: "4px" }}>
            {items.user.phone}
          </Box>
          <IconButton
            icon={CopyIcon}
            spacing="compact"
            label="Notification"
            appearance="subtle"
            onClick={() => copyToClipboard(items.user.phone.replaceAll("-", ""), "연락처를 복사하였습니다.")}
          />

          {items.firstEarlypayDate && (
            <>
              <Divider />
              <Box
                style={{
                  fontSize: "14px",
                  color: "#8590A2",
                }}
              >
                {items.firstEarlypayDate.replaceAll("-", ".")} 선정산 시작
              </Box>
            </>
          )}
        </Box>
        <Box xcss={modifyExpectedDate}>
          <Button iconBefore={EditIcon} onClick={handleClickExpectedPaybackDate}>
            회수예정일자 수정
          </Button>
          <Button onClick={() => setOpenMemo(prev => !prev)} iconBefore={MediaServicesDocumentIcon}>
            가게 메모
          </Button>
        </Box>
      </StoreInfoWrapper>
      {openMemo && (
        <MemoModal
          storeId={items.id}
          storeOwner={items.user.name}
          comments={items.comments}
          storeName={store}
          onClose={() => setOpenMemo(prev => !prev)}
        />
      )}

      <StoreDetail>
        <Box style={{ display: "flex", overflowX: "scroll" }}>
          <Box
            style={{ display: "flex", flexDirection: "column", position: "relative", width: "100%", minWidth: "300px" }}
          >
            <DetailInfo style={{ minHeight: "33px", maxHeight: "33px" }}>
              <TitleLabel>서비스 상태</TitleLabel>
              <ContentLabel>
                <DropdownMenu<HTMLButtonElement>
                  trigger={({ triggerRef, ...providedProps }) => (
                    <Box
                      style={{
                        cursor: "pointer",
                      }}
                      {...providedProps}
                      ref={triggerRef}
                    >
                      <Lozenge
                        testId="serviceStatus"
                        appearance={SERVICE_STATUS_TYPE[items.service.status].appearance as ThemeAppearance}
                        style={{
                          color: SERVICE_STATUS_TYPE[items.service.status].color,
                          backgroundColor: SERVICE_STATUS_TYPE[items.service.status].backgroundColor,
                        }}
                      >
                        {ORIGIN_SERVICE_STATUS[items.service.status]}
                      </Lozenge>
                    </Box>
                  )}
                  shouldRenderToParent
                >
                  <DropdownItemGroup>
                    {filteredMenuList.map((item, index) => (
                      <MenuLozengeButton
                        key={index}
                        onClick={() => onClickSetServiceStatus({ status: item.id, isEditInfo: false })}
                      >
                        <Lozenge appearance={item.appearance as ThemeAppearance} style={item.style}>
                          {item.value}
                        </Lozenge>
                      </MenuLozengeButton>
                    ))}
                  </DropdownItemGroup>
                </DropdownMenu>
                {/* <Box style={{ cursor: "pointer" }} onClick={() => setShowServiceStatus(prev => !prev)}>
                    <Lozenge
                      testId="serviceStatus"
                      appearance={SERVICE_STATUS_TYPE[currentServiceStatus].appearance as ThemeAppearance}
                      style={{
                        color: SERVICE_STATUS_TYPE[currentServiceStatus].color,
                        backgroundColor: SERVICE_STATUS_TYPE[currentServiceStatus].backgroundColor,
                      }}
                    >
                      {ORIGIN_SERVICE_STATUS[currentServiceStatus]}
                    </Lozenge>
                  </Box>
                  {showServiceStatus && (
                    <MenuGroupContainer ref={modalRef}>
                      <MenuGroup maxWidth={180}>
                        <Section isList>
                          {filteredMenuList.map((item, index) => (
                            <MenuLozengeButton
                              key={index}
                              onClick={() => onClickSetServiceStatus({ status: item.id, isEditInfo: false })}
                            >
                              <Lozenge appearance={item.appearance as ThemeAppearance} style={item.style}>
                                {item.value}
                              </Lozenge>
                            </MenuLozengeButton>
                          ))}
                        </Section>
                      </MenuGroup>
                    </MenuGroupContainer>
                  )} */}
                {openChangeStatusModal.show &&
                  (currentServiceStatus === "A" && !openChangeStatusModal.isEditInfo ? (
                    <UseStatusModal
                      stopStartDate={items.service.pausedStartDate}
                      stopEndDate={items.service.pausedEndDate}
                      stopReason={items.service.terminatedReason}
                      stopDescription={items.service.terminatedDescription}
                      storeId={items.id}
                      storeName={store}
                      onClose={() => {
                        setCurrentServiceStatus(items.service.status);
                        setOpenChangeStatusModal({ show: false, isEditInfo: false });
                      }}
                      onConfirm={() => setOpenChangeStatusModal({ show: false, isEditInfo: false })}
                    />
                  ) : (
                    <ChangeStatusModal
                      originStoreStatus={items.service.status}
                      storeStatus={currentServiceStatus}
                      onClose={() => {
                        setCurrentServiceStatus(items.service.status);
                        setOpenChangeStatusModal({ show: false, isEditInfo: false });
                      }}
                      onConfirm={(changeStatus: "A" | "WPNS" | "WP" | "P" | "PC" | "TP" | "TC" | "T") => {
                        setCurrentServiceStatus(changeStatus);
                        setOpenChangeStatusModal({ show: false, isEditInfo: false });
                      }}
                      storeId={items.id.toString()}
                      isEditInfo={openChangeStatusModal.isEditInfo}
                      storeService={items.service}
                    />
                  ))}
              </ContentLabel>
            </DetailInfo>
            <DetailInfo style={{ height: items.service.pausedStartDate ? "100%" : "33px" }}>
              <TitleLabel>선정산 정지 기간</TitleLabel>
              {items.service.pausedStartDate && (
                <ContentLabel>
                  {items.service.pausedStartDate} ~ {items.service.pausedEndDate ?? ""}
                </ContentLabel>
              )}
              {canEditDate && (
                <IconButton
                  icon={EditFilledIcon}
                  label="Edit"
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => onClickSetServiceStatus({ status: items.service.status, isEditInfo: true })}
                />
              )}
            </DetailInfo>
            <DetailInfo style={{ height: items.service.terminatedReason ? "100%" : "33px" }}>
              <TitleLabel>사유 및 상세 사유</TitleLabel>
              {items.service.terminatedReason ? (
                <ContentLabel>
                  {REASON[items.service.terminatedReason]} / {items.service.terminatedDescription}
                </ContentLabel>
              ) : (
                <Box style={{ padding: "4px" }} />
              )}
              {canEditReason && (
                <IconButton
                  icon={EditFilledIcon}
                  label="Edit"
                  spacing="compact"
                  appearance="subtle"
                  onClick={() => onClickSetServiceStatus({ status: items.service.status, isEditInfo: true })}
                />
              )}
            </DetailInfo>
            <DetailInfo style={{ minHeight: "33px", maxHeight: "33px" }}>
              <TitleLabel>
                <a
                  href={`https://admin.earlypay.co.kr/admin/earlypay/underdeposit/?is_fully_repaid__exact=0&q=${items.title}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#626f86" }}
                >
                  미수금
                </a>
              </TitleLabel>
              <ContentLabel>{`${changeNumberToMoney(items.underDeposit)} 원`}</ContentLabel>
            </DetailInfo>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column", position: "relative", width: "100%" }}>
            <DetailInfo style={{ minHeight: "33px", maxHeight: "33px" }}>
              <TitleLabel>매출 유형</TitleLabel>
              <ContentLabel>{SALES_TYPE[items.salesType]}</ContentLabel>
            </DetailInfo>
            <DetailInfo style={{ height: "100%" }}>
              <TitleLabel>연동 플랫폼</TitleLabel>
              <ContentLabel style={{ minWidth: "312px" }}>
                <ConnectedPlatformList
                  fields={fields}
                  control={control}
                  isCloseService={isCloseService}
                  isDisabledSave={isDisabled}
                />
              </ContentLabel>
            </DetailInfo>
          </Box>
          <DetailInfoWrapper>
            <DetailInfo style={{ minHeight: "33px", maxHeight: "33px" }}>
              <TitleLabel>
                <a href="https://www.cardsales.or.kr/" target="_blank" rel="noreferrer" style={{ color: "#626f86" }}>
                  CFA ID
                </a>
              </TitleLabel>
              <ContentLabel>
                <Box xcss={idPwBox}>
                  {items.cfaAccount}
                  <IconButton
                    icon={CopyIcon}
                    spacing="compact"
                    label="Notification"
                    appearance="subtle"
                    onClick={() => copyToClipboard(items.cfaAccount, "CFA ID를 복사하였습니다.")}
                  />
                </Box>
              </ContentLabel>
            </DetailInfo>
            {items.salesType === "CA" && (
              <DetailInfo>
                <TitleLabel></TitleLabel>
                <ContentLabel></ContentLabel>
              </DetailInfo>
            )}
            {baminAccount && (
              <DetailInfo>
                <TitleLabel>
                  <a
                    href="https://biz-member.baemin.com/login?returnUrl=https%3A%2F%2Fself.baemin.com%2F&__ts=1734504730119"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#626f86" }}
                  >
                    배달의민족 ID/PW
                  </a>
                </TitleLabel>
                <ContentLabel style={{ minWidth: "320px" }}>
                  <Box xcss={idPwBox}>
                    {baminAccount.identification}
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() =>
                        copyToClipboard(baminAccount.identification, "배달의민족 아이디를 복사하였습니다.")
                      }
                    />
                  </Box>
                  <Box xcss={idPwBox}>
                    ••••••••••••
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() => copyToClipboard(baminAccount.password, "배달의민족 비밀번호를 복사하였습니다.")}
                    />
                  </Box>
                  <DropdownMenu<HTMLButtonElement>
                    trigger={({ triggerRef, ...providedProps }) => (
                      <Box
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                        {...providedProps}
                        ref={triggerRef}
                      >
                        <Lozenge appearance={DELIVERY_STATUS[baminAccount.status].appearance as ThemeAppearance}>
                          {DELIVERY_STATUS[baminAccount.status].text}
                        </Lozenge>
                      </Box>
                    )}
                    shouldRenderToParent
                  >
                    <DropdownItemGroup>
                      {filteredDeliveryStatus(baminAccount.status).map((item, index) => (
                        <MenuLozengeButton
                          key={index}
                          onClick={() => handlePatchPlatformStatus(baminAccount.id, item[0])}
                        >
                          <Lozenge key={index} appearance={item[1].appearance as ThemeAppearance}>
                            {item[1].text}
                          </Lozenge>
                        </MenuLozengeButton>
                      ))}
                    </DropdownItemGroup>
                  </DropdownMenu>
                </ContentLabel>
              </DetailInfo>
            )}
            {yogiyoAccount && (
              <DetailInfo>
                <TitleLabel style={{ textDecoration: "underline" }}>
                  <a
                    href="https://ceo.yogiyo.co.kr/login/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#626f86" }}
                  >
                    요기요 ID/PW
                  </a>
                </TitleLabel>
                <ContentLabel style={{ minWidth: "320px" }}>
                  <Box xcss={idPwBox}>
                    {yogiyoAccount.identification}
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() => copyToClipboard(yogiyoAccount.identification, "요기요 아이디를 복사하였습니다.")}
                    />
                  </Box>
                  <Box xcss={idPwBox}>
                    ••••••••••••
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() => copyToClipboard(yogiyoAccount.password, "요기요 비밀번호를 복사하였습니다.")}
                    />
                  </Box>
                  <DropdownMenu<HTMLButtonElement>
                    trigger={({ triggerRef, ...providedProps }) => (
                      <Box
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                        {...providedProps}
                        ref={triggerRef}
                      >
                        <Lozenge appearance={DELIVERY_STATUS[yogiyoAccount.status].appearance as ThemeAppearance}>
                          {DELIVERY_STATUS[yogiyoAccount.status].text}
                        </Lozenge>
                      </Box>
                    )}
                    shouldRenderToParent
                  >
                    <DropdownItemGroup>
                      {filteredDeliveryStatus(yogiyoAccount.status).map((item, index) => (
                        <MenuLozengeButton
                          key={index}
                          onClick={() => handlePatchPlatformStatus(yogiyoAccount.id, item[0])}
                        >
                          <Lozenge key={index} appearance={item[1].appearance as ThemeAppearance}>
                            {item[1].text}
                          </Lozenge>
                        </MenuLozengeButton>
                      ))}
                    </DropdownItemGroup>
                  </DropdownMenu>
                </ContentLabel>
              </DetailInfo>
            )}
            {coupangAccount && (
              <DetailInfo>
                <TitleLabel style={{ textDecoration: "underline" }}>
                  <a
                    href="https://store.coupangeats.com/merchant/login"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#626f86" }}
                  >
                    쿠팡이츠 ID/PW
                  </a>
                </TitleLabel>
                <ContentLabel style={{ minWidth: "320px" }}>
                  <Box xcss={idPwBox}>
                    {coupangAccount.identification}
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() =>
                        copyToClipboard(coupangAccount.identification, "쿠팡이츠 아이디를 복사하였습니다.")
                      }
                    />
                  </Box>
                  <Box xcss={idPwBox}>
                    ••••••••••••
                    <IconButton
                      icon={CopyIcon}
                      spacing="compact"
                      label="Notification"
                      appearance="subtle"
                      onClick={() => copyToClipboard(coupangAccount.password, "쿠팡이츠 비밀번호를 복사하였습니다.")}
                    />
                  </Box>
                  {/* <Lozenge appearance={DELIVERY_STATUS[coupangAccount.status].appearance as ThemeAppearance}>
                      {DELIVERY_STATUS[coupangAccount.status].text}
                    </Lozenge> */}
                  <DropdownMenu<HTMLButtonElement>
                    trigger={({ triggerRef, ...providedProps }) => (
                      <Box
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                        {...providedProps}
                        ref={triggerRef}
                      >
                        <Lozenge appearance={DELIVERY_STATUS[coupangAccount.status].appearance as ThemeAppearance}>
                          {DELIVERY_STATUS[coupangAccount.status].text}
                        </Lozenge>
                      </Box>
                    )}
                    shouldRenderToParent
                  >
                    <DropdownItemGroup>
                      {filteredDeliveryStatus(coupangAccount.status).map((item, index) => (
                        <DropdownItem key={index}>
                          <MenuLozengeButton onClick={() => handlePatchPlatformStatus(coupangAccount.id, item[0])}>
                            <Lozenge key={index} appearance={item[1].appearance as ThemeAppearance}>
                              {item[1].text}
                            </Lozenge>
                          </MenuLozengeButton>
                        </DropdownItem>
                      ))}
                    </DropdownItemGroup>
                  </DropdownMenu>
                </ContentLabel>
              </DetailInfo>
            )}

            {/* <SaveInfoButton>
                <Button appearance="primary" type="submit" isDisabled={isDisabled}>
                  저장
                </Button>
              </SaveInfoButton> */}
          </DetailInfoWrapper>
          {/* 
            <SaveInfoButton>
              <Button appearance="primary" type="submit" spacing="compact" isDisabled={isDisabled}>
                저장
              </Button>
            </SaveInfoButton> */}
        </Box>
      </StoreDetail>
      <ModalTransition>
        {isVisibleExpectedPaybackDate && depositDate?.lastDepositDate && (
          <EditExpectedPaybackDateModal
            onClose={closeExpectedPaybackDateModal}
            lastDepositDate={depositDate.lastDepositDate}
            store={items}
          />
        )}
      </ModalTransition>
    </form>
  );
};

export default StoreInfoBox;

const StoreInfoWrapper = styled.div`
  overflow-x: auto;
  background-color: #091e420f;
  padding: 6px 12px;
  border-top: 1px solid #091e4224;
  border-left: 1px solid #091e4224;
  border-right: 1px solid #091e4224;
  border-bottom: 2px solid #091e4224;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;

const Divider = styled.span`
  width: 2px;
  height: 16px;
  background-color: #00000029;
`;

const storeInfoText = xcss({
  fontSize: "16px",
  fontWeight: 700,
  color: "color.text",
});

const storeName = xcss({
  fontSize: "16px",
  fontWeight: 700,
  textDecoration: "underline",
  color: "color.text",
});

const StoreDetail = styled.div`
  border-left: 1px solid #091e4224;
  border-right: 1px solid #091e4224;
  // border-bottom: 1px solid #091e4224;
`;

const DetailInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const DetailInfo = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #091e4224;
  height: 100%;
`;

const TitleLabel = styled.div`
  display: flex;
  min-width: 150px;
  width: 150px;
  height: 100%;
  background-color: #091e420f;
  padding: 0px 12px;
  align-items: center;
  color: #626f86;
  font-size: 14px;
  font-weight: 500;
`;

const ContentLabel = styled.div`
  display: flex;
  padding: 4px 12px;
  color: #172b4d;
  font-size: 14px;
  font-weight: 400;
`;

const MenuLozengeButton = styled(ButtonItem)`
  padding: 9px 17px;
`;

const modifyExpectedDate = xcss({
  display: "flex",
  gap: "space.075",
  fontSize: "14px",
  fontWeight: 500,
});

const storeInfoLeft = xcss({
  display: "flex",
  flexShrink: 0,
  gap: "space.075",
  alignItems: "center",
});

const idPwBox = xcss({
  display: "flex",
  alignItems: "center",
  gap: "space.075",
  width: "132px",
});
