import React from "react";

import { AdminLoginForm } from "src/components/module/login/index";

import { LoginPage, LoginTitle } from "./index.styled";

const Login = () => {
  return (
    <LoginPage>
      <LoginTitle>얼리페이 대시보드</LoginTitle>
      <AdminLoginForm />
    </LoginPage>
  );
};

export default Login;
