import React, { useEffect } from "react";

import styled from "@emotion/styled";
import { useLocation } from "react-router";

import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import SearchIcon from "@atlaskit/icon/glyph/search";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";
import { ButtonItem, MenuGroup, Section } from "@atlaskit/menu";
import { Box, xcss } from "@atlaskit/primitives";
import Textfield from "@atlaskit/textfield";

import useDebounce from "@/hooks/useDebounce";
import { useSearchStore } from "@/hooks/useSearchStore";

import { useGetStoreList } from "@/apis/stores/getStoreList";

export interface SearchStoreBoxProps {
  placeholder?: string;
  size?: "lg" | "sm" | "md";
  hasSearchButton?: boolean;
  isNeedPadding?: boolean;
  topSpacing?: number;
}

const SERVICE_STATUS_TYPE: Record<string, { appearance: string; color?: string; backgroundColor?: string }> = {
  일시정지: { appearance: "default" },
  사용중: { appearance: "success" },
  "해지 진행": { appearance: "removed" },
  "일시정지 예정": { appearance: "new" },
  폐업정지: { appearance: "moved" },
  "폐업 해지": { appearance: "default", color: "#206A83", backgroundColor: "#E7F9FF" },
  "일반 해지": { appearance: "default", color: "#943D73", backgroundColor: "#FFECF8" },
  "매출 미발생 일시정지 예정": { appearance: "inprogress" },
};

const SearchStoreBox = ({ placeholder, isNeedPadding = false, topSpacing = 60 }: SearchStoreBoxProps) => {
  const {
    searchKeyword,
    setSearchKeyword,
    handleGoToDetail,
    handleFocus,
    handleChange,
    isOpenSearchList,
    handleKeyDown,
    selectedItemIndex,
  } = useSearchStore();
  const debouncedSearch = useDebounce(searchKeyword, 300);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParams = params.get("search");
  const startDateParams = params.get("start_date");
  const endDateParams = params.get("end_date");

  const { data } = useGetStoreList(1, 8, debouncedSearch);

  const storeList = data?.results || [];

  useEffect(() => {
    if (searchParams) {
      setSearchKeyword(searchParams);
    }
  }, [location.pathname]);

  return (
    <Box style={{ padding: isNeedPadding ? "24px 0 16px" : "0" }}>
      <Box style={{ width: "320px" }}>
        <Textfield
          appearance="standard"
          value={searchKeyword}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={e => handleKeyDown(e, storeList)}
          placeholder={placeholder}
          isCompact
          elemAfterInput={
            searchKeyword ? (
              <Box
                xcss={closeButton}
                onClick={() => {
                  setSearchKeyword("");
                }}
              >
                <EditorCloseIcon label="close" />
              </Box>
            ) : (
              <Box xcss={closeButton}>
                <SearchIcon label="search" />
              </Box>
            )
          }
        />
      </Box>
      {isOpenSearchList && data?.results && (
        <Box
          style={{
            width: "320px",
            boxShadow: "0px 8px 12px 0px #091E4226, 0px 0px 1px 0px #091E424F",
            position: "absolute",
            backgroundColor: "white",
            zIndex: 100,
            top: `${topSpacing}px`,
          }}
        >
          <MenuGroup>
            <Section>
              {storeList.map((item, index) => (
                <CustomButtonItem
                  key={index}
                  onClick={e => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(`/settlement/detail/${item.id}`, "_blank");
                    } else {
                      handleGoToDetail(item.id, item.title, startDateParams, endDateParams);
                    }
                  }}
                  isSelected={index === selectedItemIndex}
                >
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                      <StoreName>{item.title}</StoreName>
                      <Box style={{ color: "#8590A2", fontSize: "12px" }}>{item.user.name}</Box>
                    </Box>
                    <Lozenge
                      testId="serviceStatus"
                      appearance={SERVICE_STATUS_TYPE[item.serviceStatus].appearance as ThemeAppearance}
                      style={{
                        color: SERVICE_STATUS_TYPE[item.serviceStatus].color,
                        backgroundColor: SERVICE_STATUS_TYPE[item.serviceStatus].backgroundColor,
                      }}
                    >
                      {item.serviceStatus}
                    </Lozenge>
                  </Box>
                </CustomButtonItem>
              ))}
            </Section>
          </MenuGroup>
        </Box>
      )}
    </Box>
  );
};

export default SearchStoreBox;

const closeButton = xcss({
  display: "flex",
  paddingRight: "space.100",
  fontSize: "14px",
});

const CustomButtonItem = styled(ButtonItem)`
  background-color: ${({ isSelected }: { isSelected: boolean }) => (isSelected ? "#E7F9FF" : "transparent")};
  cursor: pointer;
`;

const StoreName = styled.div`
  min-width: 156px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
