import styled from "styled-components";

import { FlexBox } from "@/components/atoms";

export const CardBox = styled(FlexBox)`
  max-width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 13%), 0px 0.3px 0.9px rgb(0 0 0 / 5%);
  background: #fff;
  position: relative;

  h3 {
    font-weight: 400;
    color: #555;
    margin-bottom: 5px;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  h6 {
    font-weight: 400;
    text-align: end;
    color: #777;
    margin-bottom: 24px;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  border-radius: 8px;
`;

export const TitleBox = styled(FlexBox)`
  width: 100%;
  padding: 12px 16px;
  min-height: 51px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: 500;
    color: #1f1f1f;
  }
`;

export const ChartBox = styled(FlexBox)`
  width: 100%;
  padding: 12px 16px;

  @media (max-width: 480px) {
    padding: 12px 8px;
  }
`;
