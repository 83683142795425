import React, { useEffect, useState } from "react";

import Text from "@/components/elements/Text";

import { get_users_statistics } from "src/apis/chart";
import { ChartCard, PieChart } from "src/components/block";
import { Users } from "src/types/apiType";

const UserChartBox = () => {
  const [users, setUsers] = useState<Users>();
  const [dropDown, setDropDown] = useState([
    { key: "all", act: false },
    { key: "active", act: true },
  ]);

  const getUser = async () => {
    const { data } = await get_users_statistics("active");
    setUsers({ ...data });
  };

  useEffect(() => {
    getUser();
  }, []);

  const chageChart = async (arr: { key: string; act: boolean }[]) => {
    setDropDown([...arr]);
    const trueKey = arr.filter(x => {
      if (x.act === true) return x;
    });
    const { data } = await get_users_statistics(trueKey[0].key);
    setUsers({ ...data });
  };

  return (
    <div style={{ width: "100%" }}>
      <ChartCard _date={false} _title="사용자수" _dropDownArr={dropDown} _onClick={chageChart}>
        <Text size={18} bold>{`플랫폼별 (총:${users?.platform.platformTotal.toLocaleString()}명)`}</Text>
        <Text size={16} bold>
          얼리페이 플랫폼별 사용자 그래프
        </Text>
        <PieChart
          _chartType={3}
          _labels={["카드+배달", "카드", "배달"]}
          _series={[users?.platform.cardDelivery, users?.platform.cardOnly, users?.platform.deliveryOnly]}
        />
        <Text size={18} bold>{`성별 (총:${users?.gender?.genderTotal.toLocaleString()}명)`}</Text>
        <Text size={16} bold>
          얼리페이 성별 사용자 그래프
        </Text>
        <PieChart _chartType={6} _labels={["남성", "여성"]} _series={[users?.gender?.male, users?.gender?.female]} />
        <Text size={18} bold>{`연령별 (총:${users?.age.ageTotal.toLocaleString()}명)`}</Text>
        <Text size={16} bold>
          얼리페이 연령별 사용자 그래프
        </Text>
        <PieChart
          _chartType={2}
          _labels={["10대", "20대", "30대", "40대", "50대", "60대", "70대"]}
          _series={[
            users?.age?.age10,
            users?.age?.age20,
            users?.age?.age30,
            users?.age?.age40,
            users?.age?.age50,
            users?.age?.age60,
            users?.age?.age70,
          ]}
        />
      </ChartCard>
    </div>
  );
};

export default UserChartBox;
