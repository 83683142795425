import { useQuery } from "react-query";

import { instance } from "../instance";

// 해당 가게의 정산 통계를 조회합니다.
const get_expected_payback_date = async (store_id: number) => {
  const params = { store_id };

  const { data } = await instance({
    method: "get",
    url: `/v2/admin/platform-sales/stores/${store_id}/last-deposit-date`,
    params: params,
  });

  return data;
};

export const useGetExpectedPaybackDate = (store_id: number) => {
  return useQuery(["ExpectedPaybackDate", store_id], () => get_expected_payback_date(store_id), {
    enabled: !!store_id,
  });
};
