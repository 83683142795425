import React, { useCallback, useState } from "react";

import Button from "@atlaskit/button/new";

import { DatePicker } from "@atlaskit/datetime-picker";
import { Label } from "@atlaskit/form";
import { ErrorMessage, Field } from "@atlaskit/form";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { Box } from "@atlaskit/primitives";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Toggle from "@atlaskit/toggle";

import { usePatchServiceStatus } from "@/apis/stores/patchServiceStatus";

import { StoreServiceStatusProps } from "@/types/storeType";

import { getDate } from "@/utils/getFormattedDate";

interface ChangeStatusModalProps {
  onClose: () => void;
  onConfirm: (changeStatus: "A" | "WPNS" | "WP" | "P" | "PC" | "TP" | "TC" | "T") => void;
  storeId?: string;
  originStoreStatus?: string;
  storeStatus?: string;
  isEditInfo?: boolean;
  storeService: StoreServiceStatusProps;
}

const SERVICE_STATUS_TYPE_T = [
  { value: "A", label: "사용중", disabled: [] },
  { value: "WP", label: "일시정지 예정", disabled: ["TP"] },
  { value: "P", label: "일시정지", disabled: ["TC"] },
  {
    value: "PC",
    label: "폐업정지",
    disabled: ["A", "WP", "P", "TP", "T"],
  },
  { value: "TP", label: "해지진행", disabled: [] },
  {
    value: "TC",
    label: "폐업 해지",
    disabled: ["A", "WP", "P", "PC", "TP", "T"],
  },
  {
    value: "T",
    label: "일반 해지",
    disabled: ["A", "WP", "P", "PC", "TP", "TC"],
  },
];

const SERVICE_STATUS_TYPE = [
  { label: "일시정지", value: "P" },
  { label: "사용중", value: "A" },
  { label: "해지진행", value: "TP" },
  { label: "일시정지 예정", value: "WP" },
  { label: "폐업정지", value: "PC" },
  { label: "폐업 해지", value: "TC" },
  { label: "일반 해지", value: "T" },
];

const REASON = [
  { label: "일반", value: "NORMAL" },
  { label: "폐업", value: "CLOSED" },
  { label: "양도/양수", value: "TRANSFERED OWNERSHIP" },
  { label: "서비스 불만", value: "UNSATISFIED" },
  { label: "휴면", value: "DORMANT" },
  { label: "강제", value: "FORCED" },
  { label: "기타", value: "ETC" },
];

const ChangeStatusModal = ({
  originStoreStatus,
  storeStatus,
  onClose,
  onConfirm,
  isEditInfo = false,
  storeId,
  storeService,
}: ChangeStatusModalProps) => {
  const today = getDate("now", "yyyy-mm-dd");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [currentStoreStatus, setCurrentStoreStatus] = useState<string>(storeStatus);
  const [settingScfStartDate, setSettingScfStartDate] = useState<string>(today);

  const [isCheckedToggle, setIsCheckedToggle] = useState<boolean>(false);

  const mutation = usePatchServiceStatus(storeId, onClose, () =>
    onConfirm(currentStoreStatus as "A" | "WPNS" | "WP" | "P" | "PC" | "TP" | "TC" | "T"),
  );

  const originServiceStatus = SERVICE_STATUS_TYPE.find(item => item.value === originStoreStatus);
  const serviceStatus = SERVICE_STATUS_TYPE.find(item => item.value === storeStatus);

  const filterOption = SERVICE_STATUS_TYPE_T.filter(
    item =>
      item.value !== originStoreStatus &&
      !SERVICE_STATUS_TYPE_T.find(status => status.value === originStoreStatus).disabled.includes(item.value),
  );

  const disabledScfStartDate = currentStoreStatus === "TP" || currentStoreStatus === "P" || currentStoreStatus === "PC";
  const hideScfStartDate = currentStoreStatus === "T" || currentStoreStatus === "TC";
  const hideToggle = currentStoreStatus === "PC" || currentStoreStatus === "T" || currentStoreStatus === "TC";
  const hideReason = currentStoreStatus === "PC";

  const getDateAfterDays = (dateString: string, days: number) => {
    const date = new Date(dateString);

    date.setDate(date.getDate() + days);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const nextDay = getDateAfterDays(today, 1);
  const sevenDaysAfterScfEndDate = getDateAfterDays(settingScfStartDate, 7);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const data = new FormData(e.target as HTMLFormElement);
      const obj: Record<string, FormDataEntryValue> = {};

      data.forEach((val, key) => {
        obj[key] = val;
      });

      const payload = {
        status: obj.status ?? storeService.status,
        pausedStartDate: obj.pausedStartDate ?? storeService.pausedStartDate,
        pausedEndDate: obj.pausedEndDate ?? storeService.pausedEndDate,
        terminatedReason: obj.terminatedReason ?? null,
        terminatedDescription: obj.detailReason ?? "",
      } as StoreServiceStatusProps;

      if (!obj.pausedStartDate && !isEditInfo && obj.status !== "T" && obj.status !== "TC") {
        setErrors({ ...errors, pausedStartDate: "필수 항목입니다. 날짜를 선택해주세요." });
        return;
      }

      if (isCheckedToggle && !obj.pausedEndDate && !isEditInfo) {
        setErrors({ ...errors, pausedEndDate: "필수 항목입니다. 날짜를 선택해주세요." });
        return;
      }

      if (obj.status !== "PC" && !obj.terminatedReason) {
        setErrors({ ...errors, terminatedReason: "필수 항목입니다. 사유를 선택해주세요." });
        return;
      }

      mutation.mutateAsync(payload);
      onConfirm(obj.status as "A" | "WPNS" | "WP" | "P" | "PC" | "TP" | "TC" | "T");
    },
    [errors],
  );

  const checkStartDate = () => {
    if (storeService.pausedStartDate) {
      return storeService.pausedStartDate;
    }
    return today;
  };

  return (
    <ModalTransition>
      <Modal
        onClose={onClose}
        width="medium"
        height={["WP", "P", "TP", "TC", "T"].includes(currentStoreStatus) ? "82%" : "60%"}
        autoFocus={false}
      >
        <form
          style={{
            height: "100%",
          }}
          onSubmit={onSubmit}
        >
          <ModalHeader>
            <ModalTitle>
              <Box
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {isEditInfo
                  ? `${SERVICE_STATUS_TYPE.find(item => item.value === originStoreStatus).label} 정보 수정`
                  : "상태 변경을 위한 정보 입력"}
              </Box>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Box style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              {!isEditInfo && (
                <Box style={{ display: "flex", gap: "8px" }}>
                  <Box style={{ width: "100%" }}>
                    <Label htmlFor="origin-service-status">기존 서비스 상태</Label>
                    <Select
                      inputId="origin-service-status"
                      name="originStatus"
                      classNamePrefix="react-select"
                      value={originServiceStatus}
                      placeholder="서비스 상태"
                      isDisabled
                    />
                  </Box>
                  <Box style={{ width: "100%" }}>
                    <Label htmlFor="change-service-status">변경할 서비스 상태</Label>
                    <Select
                      inputId="change-service-status"
                      name="status"
                      classNamePrefix="react-select"
                      options={filterOption}
                      defaultValue={serviceStatus}
                      onChange={value => setCurrentStoreStatus(value.value)}
                      placeholder="서비스 상태"
                    />
                  </Box>
                </Box>
              )}

              <Box>
                <Box style={{ display: "flex", gap: "8px" }}>
                  {!hideScfStartDate && (
                    <Box style={{ width: "100%" }}>
                      <Field name="pausedStartDate" label="선정산 정지 시작일" isRequired>
                        {({ fieldProps }) => (
                          <>
                            <DatePicker
                              {...fieldProps}
                              locale="ko"
                              value={
                                currentStoreStatus === "P" || currentStoreStatus === "TP" || currentStoreStatus === "PC"
                                  ? checkStartDate()
                                  : null
                              }
                              defaultValue={currentStoreStatus === "WP" && nextDay}
                              isDisabled={disabledScfStartDate}
                              placeholder="YYYY-MM-DD"
                              onChange={value => setSettingScfStartDate(value)}
                            />
                            {errors.pausedStartDate && <ErrorMessage>{errors.pausedStartDate}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </Box>
                  )}

                  {(isCheckedToggle || storeService.pausedEndDate) && (
                    <Box style={{ width: "100%" }}>
                      <Field name="pausedEndDate" label="선정산 정지 종료일" isRequired>
                        {({ fieldProps }) => (
                          <>
                            <DatePicker
                              {...fieldProps}
                              locale="ko"
                              minDate={sevenDaysAfterScfEndDate}
                              defaultValue={storeService.pausedEndDate ?? null}
                              placeholder="YYYY-MM-DD"
                            />
                            {errors.pausedEndDate && <ErrorMessage>{errors.pausedEndDate}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    </Box>
                  )}
                </Box>
                {!hideToggle && (
                  <Box style={{ display: "flex", gap: "6px", alignItems: "center", justifyContent: "flex-end" }}>
                    <Label htmlFor="toggle-controlled">선정산 정지 종료일</Label>
                    <Toggle
                      id="toggle-controlled"
                      onChange={() => setIsCheckedToggle(prev => !prev)}
                      isChecked={isCheckedToggle}
                    />
                  </Box>
                )}
              </Box>

              {!hideReason && (
                <>
                  <Box style={{ width: "100%" }}>
                    <Field name="terminatedReason" label="사유" isRequired>
                      {() => (
                        <>
                          <Select
                            inputId="reason-status"
                            name="terminatedReason"
                            className="single-select"
                            classNamePrefix="react-select"
                            options={REASON}
                            defaultValue={REASON.find(item => item.value === storeService.terminatedReason)}
                            placeholder="사유 선택"
                          />
                          {errors.terminatedReason && <ErrorMessage>{errors.terminatedReason}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box style={{ width: "100%" }}>
                    <Field name="detailReason" label="상세 사유">
                      {() => (
                        <>
                          <Textfield
                            name="detailReason"
                            placeholder="상세 사유 작성 (선택 사항)"
                            defaultValue={storeService.terminatedDescription}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              취소
            </Button>
            <Button type="submit" appearance="primary">
              완료
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </ModalTransition>
  );
};

export default ChangeStatusModal;
