import { DatePicker } from "@carbon/react";
import styled from "styled-components";

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 32px;
`;

export const DatePickerWrapper = styled(DatePicker)`
  display: flex;
  flex: 0 0 auto;

  .cds--date-picker--range {
    gap: 8px;
  }
`;

export const SearchStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;
