import { useQuery } from "react-query";

import { instance } from "../instance";

interface CfaAccountProps {
  ok: boolean;
  results: {
    identification: string;
  }[];
}
const getCfaAccounts = async () => {
  const { data } = await instance<CfaAccountProps>({
    method: "get",
    url: "/v2/admin/cfa-accounts",
  });

  return data.results;
};

export const useGetCfaAccounts = () => {
  const { data, isLoading } = useQuery<CfaAccountProps["results"], Error>(["CfaAccounts"], () => getCfaAccounts());
  return {
    data,
    isLoading,
  };
};
