import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useLocation } from "react-router";

import Button from "@atlaskit/button/new";

import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import RetryIcon from "@atlaskit/icon/glyph/retry";

import { DatePicker } from "@atlaskit/datetime-picker";
import { Box, xcss } from "@atlaskit/primitives";

import { useNavigateSearch } from "@/hooks/useNavigateSearch";

import { getDate } from "@/utils/getFormattedDate";

import { SearchStack, SearchWrapper } from "./index.styled";

export const SearchDetailBox: React.FC = () => {
  const { navigateSearch } = useNavigateSearch();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const searchParams = new URLSearchParams(location.search);
  const storeId = pathname.pop();
  const searchKeyword = searchParams.get("search") || "";
  const start_date = searchParams.get("start_date") || "";
  const end_date = searchParams.get("end_date") || "";
  const today = getDate("now", "yyyy-mm-dd");

  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  // const [dateFocus, setDateFocus] = useState<string>("start");

  const onClickSearchButton = () => {
    const formattedStartDate = getDate(startDate, "yyyy-mm-dd");
    const formattedEndDate = getDate(endDate, "yyyy-mm-dd");
    navigateSearch(`/settlement/detail/${storeId}`, {
      search: searchKeyword,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
  };

  /** 날짜 초기화 함수 */
  /** 날짜를 선택할 때는 undefined 값이 들어오고, 날짜 초기화 버튼을 눌렀을 때는 '당일' 날짜로 설정됩니다. */
  const onResetDate = () => {
    setStartDate(today);
    setEndDate(today);
  };

  /** 회수예정날짜 시작, 종료 변경 함수 */
  // const onChangeDate = (dates: Date[]) => {
  //   dateFocus === "start" && setStartDate(dates[0]);
  //   dateFocus === "end" && setEndDate(dates[1]);
  // };

  // 날짜 문자열을 yyyy.mm.dd 형식으로 변환하는 함수
  // const formatInputDate = (input: string) => {
  //   if (input.length === 8) {
  //     // 8자리 문자열일 때 yyyy.mm.dd로 변환
  //     const year = input.slice(0, 4);
  //     const month = input.slice(4, 6);
  //     const day = input.slice(6, 8);
  //     return `${year}.${month}.${day}`;
  //   }
  //   return input; // 8자리가 아니면 그대로 반환
  // };

  // onBlur 이벤트 핸들러
  // const handleBlur = (event: React.FocusEvent<HTMLInputElement>, type: "start" | "end") => {
  //   const inputValue = event.target.value.replace(/\D/g, ""); // 숫자만 추출
  //   if (inputValue.length === 8) {
  //     const formattedDate = formatInputDate(inputValue);
  //     const parsedDate = new Date(formattedDate.replace(/\./g, "-")); // yyyy.mm.dd -> yyyy-mm-dd로 변환 후 Date 객체로 파싱

  //     if (!isNaN(parsedDate.getTime())) {
  //       if (type === "start") {
  //         setStartDate(parsedDate);
  //         event.target.value = formattedDate; // 입력 필드에 포맷된 날짜를 설정
  //       } else {
  //         setEndDate(parsedDate);
  //         event.target.value = formattedDate; // 입력 필드에 포맷된 날짜를 설정
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    setStartDate(start_date);
    setEndDate(end_date);
  }, [location.search]);

  return (
    <SearchWrapper>
      <SearchStack>
        <SeacrhWrapper>
          <DateWrapper>
            <DatePicker
              id="start-date"
              onChange={value => setStartDate(value)}
              value={startDate}
              locale="ko"
              maxDate={today}
              spacing="compact"
              placeholder="시작일"
            />
            <Box>
              <ArrowRightIcon label="" primaryColor="#B3B9C4" />
            </Box>
            <DatePicker
              id="end-date"
              onChange={value => setEndDate(value)}
              value={endDate}
              locale="ko"
              //maxDate={today}
              minDate={startDate}
              spacing="compact"
              placeholder="종료일"
            />
          </DateWrapper>
          <Box xcss={commonButtonStyle}>
            <Button appearance="primary" onClick={onClickSearchButton} isDisabled={!startDate || !endDate}>
              검색
            </Button>
          </Box>
          <Box xcss={commonButtonStyle}>
            <Button iconAfter={RetryIcon} appearance="subtle" onClick={onResetDate}>
              초기화
            </Button>
          </Box>
        </SeacrhWrapper>
        {/* <DatePickerWrapper
          locale="ko"
          dateFormat={`Y.m.d`}
          datePickerType="range"
          value={[startDate, endDate]}
          onChange={onChangeDate}
          allowInput={true}
          maxDate={dateFocus === "start" ? endDate : null}
          minDate={dateFocus === "end" ? startDate : null}
        >
          <DatePickerInput
            id="date-picker-input-id-start"
            placeholder="yyyy.mm.dd"
            labelText="회수예정날짜 시작"
            size="lg"
            onFocus={() => setDateFocus("start")}
            onBlur={event => handleBlur(event, "start")}
          />
          <DatePickerInput
            id="date-picker-input-id-finish"
            placeholder="yyyy.mm.dd"
            labelText="회수예정날짜 종료"
            size="lg"
            onFocus={() => setDateFocus("end")}
            onBlur={event => handleBlur(event, "end")}
          />
        </DatePickerWrapper> */}
      </SearchStack>
    </SearchWrapper>
  );
};

export default SearchDetailBox;

const commonButtonStyle = xcss({
  fontSize: "14px",
});

const SeacrhWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
