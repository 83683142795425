import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useLocation } from "react-router";

import { IconButton } from "@atlaskit/button/new";

import NotificationIcon from "@atlaskit/icon/glyph/notification";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import SignInIcon from "@atlaskit/icon/glyph/sign-in";
import UserAvatarCircleIcon from "@atlaskit/icon/glyph/user-avatar-circle";

import { TopNavigation } from "@atlaskit/page-layout";
import { Box } from "@atlaskit/primitives";

import { useNavigateSearch } from "@/hooks/useNavigateSearch";

import { deleteToken } from "@/utils/token";

type NavType = {
  title: string;
  route: string;
  key: string;
};

const Header = () => {
  const location = useLocation();
  const { navigateSearch } = useNavigateSearch();
  const path: string = useLocation().pathname.split("/")[1];
  const pathName: string = path === "" ? "home" : path;

  const [nav, setNav] = useState<NavType[]>([
    { title: "대시보드", route: "https://grafana.earlypay.co.kr/dashboards", key: "home" },
    { title: "퍼널분석", route: "/funnel", key: "funnel" },
    { title: "어드민", route: "/admin/store", key: "admin" },
    { title: "정산 조정", route: "/settlement", key: "settlement" },
  ]);

  const handleLogout = (): void => {
    deleteToken("ep_tk");
    navigateSearch("/login");
  };

  useEffect((): void => {
    const selectNavBar: NavType[] = nav.map(page => {
      if (pathName === page.key) return { ...page, active: true };
      return { ...page, active: false };
    });

    setNav([...selectNavBar]);
  }, [location.search]);

  return (
    <Box style={{ width: "100vw" }}>
      <TopNavigation height={64} isFixed={false}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            padding: "12px 12px 12px 20px",
            justifyContent: "space-between",
            borderBottom: "1px solid #091E4224",
          }}
        >
          <Logo onClick={() => navigateSearch("/")}>
            <img src="/logo.png" alt="logo" width={126} height={24} />
          </Logo>
          <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <IconButton icon={NotificationIcon} label="Notification" appearance="subtle" />
            <IconButton icon={SettingsIcon} label="Setting" appearance="subtle" />
            <IconButton icon={UserAvatarCircleIcon} label="Person" appearance="subtle" />
            <IconButton icon={SignInIcon} label="Logout" onClick={handleLogout} appearance="subtle" />
          </Box>
        </Box>
      </TopNavigation>
    </Box>
  );
};

export default Header;

const Logo = styled.div`
  padding: 4px;
  display: flex;
  cursor: pointer;
`;
