import React, { useRef, useState } from "react";

import styled from "@emotion/styled";

import Button from "@atlaskit/button/new";

import { DatePicker } from "@atlaskit/datetime-picker";
import { Label } from "@atlaskit/form";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "@atlaskit/modal-dialog";
import { Box } from "@atlaskit/primitives";

import { useGetExpectedPaybackDate } from "@/apis/deposit/getSumExpectedPaybackDate";
import { useEditExpectedPaybackDate } from "@/apis/deposit/postExpectedPaybackDate";

import { StoreDetailProps } from "@/types/storeType";

import { changeNumberToMoney } from "@/utils/changeNumberToMoney";
import { getDate } from "@/utils/getFormattedDate";

import Text from "@/components/elements/Text";

import { DateWrapper } from "./index.styled";

export interface EditExpectedPaybackDateModalProps {
  onClose: () => void;
  lastDepositDate: string;
  store: StoreDetailProps;
}

export const EditExpectedPaybackDateModal: React.FC<EditExpectedPaybackDateModalProps> = ({
  onClose,
  lastDepositDate,
  store,
}) => {
  const today = getDate("now", "yyyy-mm-dd");
  const [editedExpectedPaybackDate, setEditedExpectedPaybackDate] = useState(today);
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState<boolean>(false);
  const focusRef = useRef<HTMLSpanElement>(null);

  const editedDate = new Date(editedExpectedPaybackDate);
  const changedStartDate = getDate(
    `${editedDate.getFullYear()}-${editedDate.getMonth() + 1}-${editedDate.getDate() + 1}`,
  );
  const changedEndDate = getDate(new Date(lastDepositDate));

  const lastDate = new Date(lastDepositDate);
  const maxDate = new Date(lastDate.setDate(lastDate.getDate() - 1));
  const maxFormatDate = `${maxDate.getFullYear()}-${String(maxDate.getMonth() + 1).padStart(2, "0")}-${String(
    maxDate.getDate(),
  ).padStart(2, "0")}`;

  const isDisabled = new Date(lastDepositDate) < new Date() || !editedExpectedPaybackDate.length;

  const mutation = useEditExpectedPaybackDate(store.id);

  /** 수정 완료 버튼을 눌렀을 경우 */
  const handleSave = () => {
    setIsVisibleConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setIsVisibleConfirmModal(false);
    onClose();
  };

  const handleCancelConfirmModal = () => {
    setIsVisibleConfirmModal(false);
  };

  const handleChangeDate = value => {
    setEditedExpectedPaybackDate(getDate(value, "yyyy-mm-dd"));
  };

  const handleSubmit = () => {
    mutation.mutate({
      date: getDate(editedExpectedPaybackDate, "yyyy-mm-dd"),
    });
    setIsVisibleConfirmModal(false);
    onClose();
  };

  const { data } = useGetExpectedPaybackDate(
    store.id,
    getDate(changedStartDate, "yyyy-mm-dd"),
    getDate(changedEndDate, "yyyy-mm-dd"),
  );
  const totalAmount = data?.totalAmount ? changeNumberToMoney(data.totalAmount) : "0";

  return (
    <>
      {!isVisibleConfirmModal ? (
        <Modal autoFocus={focusRef} onClose={onClose} width="medium">
          <ModalHeader>
            <ModalTitle>
              <Title>회수예정일자 수정</Title>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <DateWrapper>
              <Box style={{ width: "100%" }}>
                <Label htmlFor="fix-date">수정할 회수 예정일자</Label>
                <DatePicker
                  id="fix-date"
                  locale="ko"
                  minDate={today}
                  maxDate={maxFormatDate}
                  value={editedExpectedPaybackDate}
                  onChange={handleChangeDate}
                  autoFocus={false}
                />
              </Box>
              <Box style={{ width: "100%" }}>
                <Label htmlFor="last-fix-date">마지막 회수 예정일자</Label>
                <DatePicker id="last-fix-date" locale="ko" value={lastDepositDate} autoFocus={false} isDisabled />
              </Box>
              {/* <DatePicker
                datePickerType="single"
                dateFormat={`Y.m.d`}
                locale="ko"
                minDate={today}
                maxDate={maxDate}
                value={editedExpectedPaybackDate}
                onChange={handleChangeDate}
              >
                <CustomDatePickerInput
                  placeholder="yyyy.mm.dd"
                  labelText="수정할 회수 예정일자"
                  id="date-picker-single"
                  size="lg"
                  allowInput={false}
                  warn={new Date(lastDepositDate) < new Date()}
                  warnText="수정 가능한 회수 예정일자가 없습니다."
                  style={{ backgroundColor: themes.white.field01 }}
                />
              </DatePicker>

              <DatePicker
                datePickerType="single"
                dateFormat={`Y.m.d`}
                locale="ko"
                allowInput={false}
                value={lastDepositDate}
              >
                <CustomDatePickerInput
                  placeholder="yyyy.mm.dd"
                  labelText="마지막 회수 예정일자"
                  id="date-picker-single"
                  size="lg"
                  disabled
                  style={{ backgroundColor: "transparent" }}
                />
              </DatePicker> */}
            </DateWrapper>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              취소
            </Button>
            <Button appearance="primary" isDisabled={isDisabled} onClick={handleSave}>
              완료
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        // <ModalWrapper
        //   size="md"
        //   open={isVisible}
        //   onRequestClose={onClose}
        //   onClose={onClose}
        //   aria-label="add-deposit-modal"
        // >
        //   <ModalHeader title={"회수예정일자 수정"} />
        //   <CustomModalBody>
        //     <Wrapper>
        //       <DateWrapper>
        //         <DatePicker
        //           datePickerType="single"
        //           dateFormat={`Y.m.d`}
        //           locale="ko"
        //           minDate={today}
        //           maxDate={maxDate}
        //           value={editedExpectedPaybackDate}
        //           onChange={handleChangeDate}
        //         >
        //           <CustomDatePickerInput
        //             placeholder="yyyy.mm.dd"
        //             labelText="수정할 회수 예정일자"
        //             id="date-picker-single"
        //             size="lg"
        //             allowInput={false}
        //             warn={new Date(lastDepositDate) < new Date()}
        //             warnText="수정 가능한 회수 예정일자가 없습니다."
        //             style={{ backgroundColor: themes.white.field01 }}
        //           />
        //         </DatePicker>

        //         <DatePicker
        //           datePickerType="single"
        //           dateFormat={`Y.m.d`}
        //           locale="ko"
        //           allowInput={false}
        //           value={lastDepositDate}
        //         >
        //           <CustomDatePickerInput
        //             placeholder="yyyy.mm.dd"
        //             labelText="마지막 회수 예정일자"
        //             id="date-picker-single"
        //             size="lg"
        //             disabled
        //             style={{ backgroundColor: "transparent" }}
        //           />
        //         </DatePicker>
        //       </DateWrapper>

        //       <SubmitButtonWrapper>
        //         <Button
        //           id="submit-edit-deposit-button"
        //           size="md"
        //           disabled={isDisabled}
        //           renderIcon={SaveIcon}
        //           onClick={handleSave}
        //         >
        //           수정완료
        //         </Button>
        //       </SubmitButtonWrapper>
        //     </Wrapper>
        //   </CustomModalBody>
        // </ModalWrapper>
        <Modal onClose={handleCloseConfirmModal}>
          <ModalHeader>
            <ModalTitle>회수일자를 아래와 같이 변경하시겠습니까?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Text>
              {`${
                changedStartDate === changedEndDate ? changedStartDate : `${changedStartDate} ~ ${changedEndDate}`
              }에 예정된 회수예정액 ${totalAmount}원이 ${getDate(
                editedExpectedPaybackDate,
              )}로 변경됩니다. 변경 후 수정이
              어렵습니다. 다시 한 번 확인해주세요.`}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={handleCancelConfirmModal}>
              취소
            </Button>
            <Button appearance="primary" isDisabled={isDisabled} onClick={handleSubmit}>
              변경하기
            </Button>
          </ModalFooter>
        </Modal>

        // <Modal
        //   open={isVisibleConfirmModal}
        //   onRequestClose={handleCloseConfirmModal}
        //   modalHeading="회수일자를 변경하시겠습니까?"
        //   primaryButtonText="변경하기"
        //   secondaryButtonText="취소"
        //   onRequestSubmit={handleSubmit}
        //   onSecondarySubmit={handleCancelConfirmModal}
        // >
        //   <ModalBody>
        //     <Text>
        //       {`${
        //         changedStartDate === changedEndDate ? changedStartDate : `${changedStartDate} ~ ${changedEndDate}`
        //       }에 예정된 회수예정액 ${totalAmount}원이 ${getDate(
        //         editedExpectedPaybackDate,
        //       )}로 변경됩니다. 변경 후 수정이
        //       어렵습니다. 다시 한 번 확인해주세요.`}
        //     </Text>
        //   </ModalBody>
        // </Modal>
      )}
    </>
  );
};

export default EditExpectedPaybackDateModal;

const Title = styled.div`
  color: #172b4d;
  font-weight: bold;
  font-size: 20px;
`;
