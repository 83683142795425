import React, { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { differenceInDays, parseISO } from "date-fns";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";

import Button from "@atlaskit/button/new";

import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";

//import { useSetRecoilState } from "recoil";
import DynamicTable from "@atlaskit/dynamic-table";
import { Box } from "@atlaskit/primitives";

import { useGetDepositDetail } from "@/apis/deposit/getDepositDetail";
import { useGetDepositOverview } from "@/apis/deposit/getDepositOverview";
import { useGetStoreDetail } from "@/apis/stores/getStoreDetail";

import Text from "@/components/elements/Text";
import DepositDetailModal from "@/components/features/settlement/DepositDetailModal";
import SearchStoreBox from "@/components/features/settlement/SearchStoreBox";
// import DepositOverviewList from "@/components/features/settlement/DepositOverviewList";
import StoreInfoBox from "@/components/features/settlement/StoreInfoBox";
import ActualAmountModal from "@/components/features/settlement/StoreInfoBox/ActualCollectionModal";
import HistoryPannel from "@/components/features/settlement/StoreInfoBox/HistoryPannel";
import SearchDetailBox from "@/components/features/settlementDetail/SearchDetailBox";

//import { selectedDepositDateState } from "@/recoil/deposit/atoms";
import { ScrollWrapper, Wrapper } from "./index.styled";
import DetailSkeleton, { TableSkeleton } from "./skeleton";

const Detail = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isVisibleDepositModal, setIsVisibleDepositsModal] = useState<boolean>(false);
  const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
  const [openHistoryDate, setOpenHistoryDate] = useState<string>("");
  const [openMismatched, setOpenMismatched] = useState<boolean>(false);
  const [checkedCfa, setCheckedCfa] = useState<string[]>([]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleCloseModal = () => {
    setOpenMismatched(false);
  };
  //const setSelectedDepositDate = useSetRecoilState(selectedDepositDateState);

  const pathname = useLocation().pathname.split("/");
  const storeId = Number(pathname.pop());
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");

  const dateDifference = startDate && endDate ? differenceInDays(parseISO(endDate), parseISO(startDate)) : 0;

  const { data: storeDetailData, isLoading } = useGetStoreDetail(storeId);
  const { data: depositOverviewData, isLoading: isOverviewLoading } = useGetDepositOverview(
    storeId,
    startDate,
    endDate,
  );
  const { data: depositDetailData, isLoading: depositDetailLoading } = useGetDepositDetail(storeId, openHistoryDate);

  const totalAmounts = depositOverviewData?.results || []; // 배열로부터 데이터를 받음
  const todayTotalAmount = totalAmounts.find(totalAmount => totalAmount.date === openHistoryDate);

  // 제외할 키 리스트
  const excludedKeys = ["date", "totalDeposit", "totalWithdraw", "totalDifference", "overDeposit", "underDeposit"];

  // 첫 번째 열에 표시될 제목
  const rowTitles = [
    { key: "expectedAmount", title: "회수예정액" },
    { key: "actualAmount", title: "회수내역" },
    { key: "difference", title: "차액" },
  ];

  useEffect(() => {
    queryClient.invalidateQueries("StoreDetail", { refetchActive: true });
  }, [location]);

  const onCloseDepositModal = () => {
    setIsVisibleDepositsModal(false);
  };

  const openHistoryList = (date: string) => {
    setShowRightPanel(true);
    setOpenHistoryDate(date);
  };

  useEffect(() => {
    const pannelElement = document.querySelector('[data-testid="rightPannel"]');

    pannelElement?.setAttribute(
      "style",
      "z-index: 300; border-left: 2px solid #091E4224; padding: 16px; overflow: auto;",
    );
  }, [showRightPanel, storeDetailData, depositDetailData]);

  if (isLoading) {
    return <DetailSkeleton />;
  }

  if (!storeDetailData) {
    return;
  }

  return (
    <Wrapper>
      {isVisibleDepositModal && (
        <DepositDetailModal
          onClose={onCloseDepositModal}
          isVisible={isVisibleDepositModal}
          storeDetailData={storeDetailData}
        />
      )}

      {/* <Breadcrumb noTrailingSlash={true}>
        <BreadcrumbItem href="/settlement">정산 조정</BreadcrumbItem>
        <BreadcrumbItem href="#">{`${storeDetailData.title}(${storeDetailData.user.name})`}</BreadcrumbItem>
      </Breadcrumb> */}

      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "8px" }}>
        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Text size={24} bold>
            정산 조정
          </Text>
          <SearchStoreBox placeholder="가게이름, 고객이름, 사업자로 검색" />
        </Box>

        <Button
          ref={buttonRef}
          onClick={event => {
            event.stopPropagation();
            setOpenMismatched(prev => !prev);
          }}
          iconAfter={ChevronDownIcon}
          appearance="primary"
        >
          실회수금 불일치 가게
        </Button>
        {openMismatched && (
          <ActualAmountModal
            buttonRef={buttonRef}
            onClose={handleCloseModal}
            checkedCfa={checkedCfa}
            setCheckedCfa={setCheckedCfa}
          />
        )}
      </Box>
      {showRightPanel && (
        <HistoryPannel
          storeDetailData={storeDetailData}
          titleDate={openHistoryDate}
          todayTotalAmount={todayTotalAmount}
          depositDetailData={depositDetailData}
          onClose={() => setShowRightPanel(prev => !prev)}
          isLoading={depositDetailLoading}
        />
      )}

      {/* <SearchStoreBox placeholder="가게이름, 고객이름, 사업자로 검색" /> */}
      <ScrollWrapper>
        {/** 가게 정보 Section */}
        <StoreInfoBox items={storeDetailData} />

        {/** 입출금 내역 Section */}
        {/* {depositOverviewData && (
          <DepositOverviewList items={depositOverviewData.results} onClickDate={onOpenDepositModal} />
        )} */}
      </ScrollWrapper>
      {/** 검색 + 회수날짜 필터링 Section */}

      <SearchDetailBox />

      {totalAmounts.map((totalAmount, index) => {
        // 카드사 데이터만 필터링하여 사용할 수 있도록 처리
        const filteredKeys = Object.keys(totalAmount).filter(key => !excludedKeys.includes(key));

        // 테이블의 행 구성
        const rows = rowTitles.map((rowTitle, rowIndex) => ({
          key: rowTitle.key,
          title: rowTitle.title,
          cells: [
            {
              content: rowTitle.title,
              style: {
                fontSize: "12px",
                backgroundColor: "#091E420F",
                color: "#44546F",
                fontWeight: "bold",
                padding: "10px 8px",
              },
            },
            ...filteredKeys.map(key => ({
              content:
                totalAmount[key]?.[rowTitle.key] !== undefined ? totalAmount[key][rowTitle.key].toLocaleString() : "-", // 해당 데이터가 없으면 '-'
              key,
              style: {
                fontSize: "14px",
                textAlign: "center",
                color: "#172B4D",
                ...(rowIndex !== rowTitles.length - 1 ? { borderBottom: "1px solid #091E4224" } : {}),
              },
            })),
          ],
        }));

        const summaryRow = [
          {
            key: "summary",
            cells: [
              {
                content: "실회수액",
                style: {
                  backgroundColor: "#091E420F",
                  fontSize: "13px",
                  color: "#172B4D",
                  fontWeight: "bold",
                  padding: "10px 8px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: totalAmount.totalWithdraw.toLocaleString(),
                style: {
                  color: totalAmount.totalWithdraw >= 0 ? "#172B4D" : "#AE2E24",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: "회수 내역 총액",
                style: {
                  backgroundColor: "#091E420F",
                  fontSize: "13px",
                  color: "#172B4D",
                  fontWeight: "bold",
                  padding: "10px 8px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: totalAmount.totalDeposit.toLocaleString(),
                style: {
                  color: totalAmount.totalDeposit >= 0 ? "#172B4D" : "#AE2E24",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: "정산 차액",
                style: {
                  backgroundColor: "#091E420F",
                  fontSize: "13px",
                  color: "#172B4D",
                  fontWeight: "bold",
                  padding: "10px 8px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: totalAmount.totalDifference.toLocaleString(),
                style: {
                  color:
                    totalAmount.totalDifference === 0
                      ? "#172B4D"
                      : totalAmount.totalDifference > 0
                      ? "#0045D6"
                      : "#AE2E24",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: "과입금",
                style: {
                  backgroundColor: "#091E420F",
                  fontSize: "13px",
                  color: "#172B4D",
                  fontWeight: "bold",
                  padding: "10px 8px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: totalAmount.overDeposit.toLocaleString(),
                style: {
                  color: totalAmount.overDeposit >= 0 ? "#172B4D" : "#AE2E24",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: "미수금",
                style: {
                  backgroundColor: "#091E420F",
                  fontSize: "13px",
                  color: "#172B4D",
                  fontWeight: "bold",
                  padding: "10px 8px",
                  borderTop: "1px solid #091E4224",
                },
              },
              {
                content: totalAmount.underDeposit.toLocaleString(),
                style: {
                  color: totalAmount.underDeposit >= 0 ? "#172B4D" : "#AE2E24",
                  fontWeight: "bold",
                  fontSize: "13px",
                  borderTop: "1px solid #091E4224",
                },
              },
            ],
          },
        ];

        // 테이블 헤더 구성
        const head = {
          cells: [
            {
              key: "title",
              content: <Box onClick={() => openHistoryList(totalAmount.date)}>{totalAmount.date}</Box>,
              style: {
                fontSize: "12px",
                backgroundColor: "#091E420F",
                textAlign: "center",
                padding: "8px 4px",
                verticalAlign: "middle",
                color: "#0045D6",
                cursor: "pointer",
                maxWidth: "94px",
                width: "94px",
              },
            },
            ...filteredKeys.map(key => ({
              key,
              content: key, // 카드사 이름이 헤더로 들어감
              style: {
                fontSize: "12px",
                textAlign: "center",
                backgroundColor: "#091E420F",
                padding: "8px 10px",
                color: "#44546F",
                fontWeight: "bold",
              },
            })),
          ],
        };

        return (
          <TableContainer key={`${totalAmount.date}_${index}`} isFirst={index === 0}>
            <DynamicTable head={head} rows={rows} />
            <SummaryTableContainer>
              <DynamicTable rows={summaryRow} />
            </SummaryTableContainer>
          </TableContainer>
        );
      })}
      {isOverviewLoading &&
        Array.from({ length: dateDifference > 10 ? 10 : dateDifference }).map((_, index) => (
          <TableSkeleton key={index} />
        ))}
    </Wrapper>
  );
};

export default Detail;

const TableContainer = styled.div<{ isFirst: boolean }>`
  position: relative;
  padding-top: ${({ isFirst }) => (isFirst ? "8px" : "20px")};
`;

const SummaryTableContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -35px;
`;
