import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  margin-bottom: 20px;
`;

export const ScrollWrapper = styled.div`
  // height: 100%;
`;
