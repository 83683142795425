import React from "react";

import { Dots, HnadlerButton, NavigationBar, NavigationButton, PageNavigationWrapper } from "./index.styled";

interface PageNavigationInterface {
  _currentPage: number;
  _setCurrentPage: (page: number) => void;
  _limite?: number;
  _searchCount: number;
}

const PageNavigation = ({ _currentPage, _setCurrentPage, _limite = 50, _searchCount }: PageNavigationInterface) => {
  const totalCount = _searchCount;
  const limite = _limite;
  const totalPage = Math.ceil(totalCount / limite);
  const buttons = Array.from({ length: totalPage }, (v, i) => i + 1);

  const minPage = totalPage - _currentPage > 2 ? _currentPage - 2 : totalPage - 4;
  const maxPage = _currentPage + 2 > 5 ? _currentPage + 2 : 5;

  const navigationButtonHnadler = (page: number) => {
    _setCurrentPage(page);
  };

  return (
    <PageNavigationWrapper id="pagenation-box">
      <PrevButtonn
        _onClick={() => {
          if (_currentPage === 1) return;
          navigationButtonHnadler(_currentPage - 1);
        }}
      />
      <NavigationBar>
        {_currentPage > 3 && <NavigationButton onClick={() => navigationButtonHnadler(1)}>1</NavigationButton>}
        {_currentPage > 3 && <Dots>...</Dots>}
        {buttons.map(page => {
          if (page > maxPage || page < minPage) return;

          return (
            <NavigationButton
              key={page}
              _currentPage={_currentPage === page}
              onClick={() => navigationButtonHnadler(page)}
              disabled={_currentPage === page}
            >
              {page}
            </NavigationButton>
          );
        })}
        {_currentPage < totalPage - 2 && <Dots>...</Dots>}
        {_currentPage < totalPage - 2 && (
          <NavigationButton onClick={() => navigationButtonHnadler(totalPage)}>{totalPage}</NavigationButton>
        )}
      </NavigationBar>
      <NextButtonn
        _onClick={() => {
          if (_currentPage === totalPage) return;
          navigationButtonHnadler(_currentPage + 1);
        }}
      />
    </PageNavigationWrapper>
  );
};

export default PageNavigation;

interface PageNationButtonInterface {
  _onClick?: () => void;
}

const NextButtonn = ({ _onClick }: PageNationButtonInterface) => {
  return <HnadlerButton onClick={_onClick}>{"⟫"}</HnadlerButton>;
};

const PrevButtonn = ({ _onClick }: PageNationButtonInterface) => {
  return <HnadlerButton onClick={_onClick}>{"⟪"}</HnadlerButton>;
};
