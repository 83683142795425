import * as Sentry from "@sentry/react";

import packageJson from "../package.json";

export const initializeServices = () => {
  const href = window.location.href;
  const isDevUri = href.split(".")[0].includes("dev");

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_URI,
    release: packageJson.version,
    environment: isDevUri || import.meta.env.DEV ? "development" : "production",
    debug: false,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/admin.earlypay.kr\.io\/api/],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
