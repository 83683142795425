import React from "react";

// date-picker
import { ko } from "date-fns/esm/locale";

import { DatePickerStyled, HeaderFilter } from "./index.styled";

interface DateBoxInterface {
  _dateObj: { start: Date; end: Date };
  _setDateObj?: (date: { start: Date; end: Date }) => void;
  _month?: boolean;
}

const DateBox = ({ _dateObj, _setDateObj, _month }: DateBoxInterface) => {
  return (
    <HeaderFilter _row>
      <DatePickerStyled
        selected={_dateObj.start}
        onChange={(date: Date) => {
          _setDateObj && _setDateObj({ ..._dateObj, start: date });
        }}
        selectsStart
        startDate={_dateObj.start}
        endDate={_dateObj.end}
        dateFormat="yyyy.MM.dd"
        locale={ko}
        minDate={new Date("2021-08-31")}
        maxDate={_dateObj.end}
        showMonthYearPicker={_month}
      />
      ~
      <DatePickerStyled
        selected={_dateObj.end}
        onChange={(date: Date) => {
          if (_month) {
            const year = new Date(date).getFullYear();
            const month = new Date(date).getMonth() + 1;

            _setDateObj && _setDateObj({ ..._dateObj, end: new Date(year, month, 0) });

            return;
          }
          _setDateObj && _setDateObj({ ..._dateObj, end: date });
        }}
        selectsEnd
        startDate={_dateObj.start}
        endDate={_dateObj.end}
        minDate={_dateObj.start}
        dateFormat="yyyy.MM.dd"
        locale={ko}
        maxDate={new Date()}
        showMonthYearPicker={_month}
      />
    </HeaderFilter>
  );
};

export default DateBox;
