import React, { useCallback } from "react";

import { Button, SelectItem, TableBody, TableHead, TableHeader, TableRow } from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { depositDetailSchema } from "@/commons/schemaValidations";

import { useEditDepositDetail } from "@/apis/deposit/patchDepositDetail";

import { DepositDetailProps } from "@/types/depositType";

import { getDate } from "@/utils/getFormattedDate";

import { DEPOSIT_COMPANY_LIST, DEPOSIT_TYPE_LIST, HEADER } from "./fieldKeyList";
import {
  CustomModal,
  CustomSelect,
  CustomTableCell,
  CustomTableRow,
  CustomTextInput,
  DepositDetailTable,
  EditModalWrapper,
  SubmitButtonWrapper,
} from "./index.styled";

export interface EditDepositModalProps {
  isVisible: boolean;
  onClose: () => void;
  selectedValues: DepositDetailProps;
}

export const EditDepositModal: React.FC<EditDepositModalProps> = ({ isVisible, selectedValues, onClose }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
  } = useForm({
    defaultValues: selectedValues,
    resolver: yupResolver(depositDetailSchema),
  });
  const mutation = useEditDepositDetail(selectedValues.id);

  /** 입출금 내역 수정 시 호출 함수 */
  const onSubmit = useCallback(
    (values: DepositDetailProps) => {
      const updatedValues = {
        cardCompany: values.cardCompany,
        depositedAt: values.depositedAt,
        amountDeposit: values.amountDeposit,
      };
      mutation.mutate(updatedValues);
      onClose();
    },
    [mutation],
  );

  return (
    <CustomModal size="md" open={isVisible} onRequestClose={onClose} onClose={onClose} aria-label="edit-deposit-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditModalWrapper>
          <DepositDetailTable>
            <TableHead>
              <TableRow>
                {HEADER.slice(0, 4).map((header, index) => (
                  <TableHeader key={index}>{header}</TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <CustomTableRow>
                {/** 입금사 선택 항목 */}
                <CustomTableCell>
                  <CustomSelect id={`deposit-company-select`} noLabel {...register(`cardCompany`)}>
                    {DEPOSIT_COMPANY_LIST.map((label, index) => (
                      <SelectItem key={index} value={label} text={label} />
                    ))}
                  </CustomSelect>
                </CustomTableCell>

                {/** 입금일시 입력 항목 */}
                <CustomTableCell>
                  <CustomTextInput
                    id="deposit-datetime-input"
                    type="datetime-local"
                    {...register(`depositedAt`, { required: true })}
                    min={`${getDate(selectedValues.depositedAt, "yyyy-mm-dd")}T00:00`}
                    max={`${getDate(selectedValues.depositedAt, "yyyy-mm-dd")}T23:59`}
                    disabled={!getValues().depositedAt.includes(getDate("now", "yyyy-mm-dd"))}
                  />
                </CustomTableCell>

                {/** 거래내역 타입 선택 항목 */}
                <CustomTableCell>
                  <CustomSelect id={`deposit-type-select`} noLabel {...register(`trxType`)} readOnly>
                    {DEPOSIT_TYPE_LIST.map((label, index) => (
                      <SelectItem key={index} value={label} text={label} />
                    ))}
                  </CustomSelect>
                </CustomTableCell>

                {/** 입출금액 입력 항목 */}
                <CustomTableCell>
                  <CustomTextInput
                    id="deposit-amount-input"
                    type="number"
                    {...register(`amountDeposit`)}
                    invalidText={""}
                  />
                </CustomTableCell>
              </CustomTableRow>
            </TableBody>
          </DepositDetailTable>

          {/** 내역 수정 버튼 */}
          <SubmitButtonWrapper>
            <Button id="submit-edit-deposit-button" type="submit" size="md" disabled={!isValid}>
              수정완료
            </Button>
          </SubmitButtonWrapper>
        </EditModalWrapper>
      </form>
    </CustomModal>
  );
};
