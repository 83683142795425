import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Box } from "@atlaskit/primitives";

import Text from "@/components/elements/Text";

const DetailSkeleton = () => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "40px",
          paddingTop: "4px",
        }}
      >
        <Text size={24} bold>
          정산 조정
        </Text>
      </Box>
      <Skeleton width={320} height={32} />
      <Box style={{ padding: "16px" }} />
      <Skeleton height={242} />
      <Box style={{ padding: "32px" }} />
      <Skeleton width={658} height={32} />
      <Box style={{ padding: "12px" }} />
      <Skeleton height={182} />
    </>
  );
};

export const TableSkeleton = () => {
  return <Skeleton width="100%" height={168} />;
};

export default DetailSkeleton;
