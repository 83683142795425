import React, { useCallback } from "react";

import Button from "@atlaskit/button/new";

import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { Box } from "@atlaskit/primitives";
import SectionMessage from "@atlaskit/section-message";

import { usePatchServiceStatus } from "@/apis/stores/patchServiceStatus";

interface ModalProps {
  stopStartDate: string;
  stopEndDate: string;
  storeId: number;
  stopReason: string;
  stopDescription: string;
  storeName: string;
  onClose: () => void;
  onConfirm: () => void;
}

const REASON = [
  { label: "일반", value: "NORMAL" },
  { label: "폐업", value: "CLOSED" },
  { label: "양도/양수", value: "TRANSFERED OWNERSHIP" },
  { label: "서비스 불만", value: "UNSATISFIED" },
  { label: "휴면", value: "DORMANT" },
  { label: "강제", value: "FORCED" },
  { label: "기타", value: "ETC" },
];

const UseStatusModal = ({
  stopStartDate,
  stopEndDate,
  storeId,
  stopReason,
  stopDescription,
  storeName,
  onClose,
  onConfirm,
}: ModalProps) => {
  const mutation = usePatchServiceStatus(storeId.toString(), onClose, onConfirm);
  const onSubmit = useCallback(() => {
    mutation.mutateAsync({
      status: "A",
      pausedStartDate: null,
      pausedEndDate: null,
      terminatedReason: null,
      terminatedDescription: null,
    });
    onConfirm();
  }, [mutation]);

  return (
    <ModalTransition>
      <Modal onClose={onClose} width="medium">
        <ModalHeader>
          <ModalTitle>
            <Box
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {storeName}의 서비스 상태를 사용중으로 변경하시겠습니까?
            </Box>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Box style={{ color: "#44546F", fontSize: "14px", paddingBottom: "16px" }}>
            변경 시, 현재 저장된 아래 정보들이 삭제됩니다.
          </Box>
          <SectionMessage appearance="warning">
            {stopStartDate && (
              <Box style={{ color: "#44546F", fontSize: "14px" }}>선정산 정지 시작일 : {stopStartDate}</Box>
            )}
            {stopEndDate && (
              <Box style={{ color: "#44546F", fontSize: "14px" }}>선정산 정지 종료일 : {stopEndDate}</Box>
            )}
            {stopReason && (
              <Box style={{ color: "#44546F", fontSize: "14px" }}>
                사유 : {REASON.find(item => item.value === stopReason).label}
              </Box>
            )}
            {stopDescription && <Box style={{ color: "#44546F", fontSize: "14px" }}>상세 사유 : {stopDescription}</Box>}
          </SectionMessage>
        </ModalBody>
        <ModalFooter>
          <Button appearance="subtle" onClick={onClose}>
            취소
          </Button>
          <Button type="submit" appearance="primary" onClick={onSubmit}>
            변경
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default UseStatusModal;
