import { atom } from "recoil";

import { IconProp } from "@atlaskit/button/dist/types/new-button/variants/types";

export const bannerState = atom({
  key: "bannerState", // 고유한 key
  default: {
    visible: false, // 배너의 가시성
    appearance: "announcement" as "error" | "announcement" | "warning", // 배너의 스타일
    message: "", // 배너에 표시할 메시지
    icon: null as React.ReactElement<IconProp, string | React.JSXElementConstructor<IconProp>>, // 배너 아이콘
  },
});
