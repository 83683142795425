import React, { useEffect, useState } from "react";

import Text from "@/components/elements/Text";

import { get_store_detail } from "src/apis/admin";
import type { ExtendStoreInterface, GetUserInterface } from "src/types/adminType";

import { ColumnTitle, DetailUserInfoWrapper, Link, Row, RowContent, RowTitle, Sections } from "./index.styled";

type ListType = [{ title: string; name: string }, { title: string; name: string }];

interface DetailUserInfoInterface {
  _storeId: number;
}

const platformSiteURL: { [url: string]: string } = {
  여신금융협회: "https://biz-member.baemin.com/login",
  KCP: "https://store.kcp.co.kr/",
  다우데이타: "https://store.kcp.co.kr/",
  제이티넷: "https://jms.jtnet.co.kr/",
  KIS정보통신: "https://semplus.kisvan.co.kr/",
  금융결제원: "https://www.kftcvan.or.kr/member/login/form.do",
  KSNET: "https://myjangbu.ksnet.co.kr/ui/scrn/cob/cob00212m0.jsp",
  한국신용카드결제: "https://kcs.koces.com/main/welcome.do",
  코밴: "https://cateca.kovan.com/nKIMOS/Default.aspx",
  KICC이지체크: "https://www.easyshop.co.kr/",
  머니온: "https://www.moneyon.com/member/regist/memberAgree.do",
  나이스정보통신: "https://newnibs.nicevan.co.kr/websquare/login.jsp",
  스마트로: "http://bizzle.co.kr/",
  SPC리치포유: "https://rf-web.spcnetworks.kr/",
  배달의민족: "https://biz-member.baemin.com/login",
  요기요: "https://ceo.yogiyo.co.kr/login/",
  쿠팡이츠: "https://store.coupangeats.com/",
};

const DetailUserInfo = ({ _storeId = 0 }: DetailUserInfoInterface) => {
  const [userInfo, setUserInfo] = useState<ExtendStoreInterface>();
  const storeId: number = _storeId;

  const getDetailStore = async (storeId = 0) => {
    const params: GetUserInterface = {
      search: "",
      page: 1,
      page_size: 100,
    };
    const userInfo: ExtendStoreInterface = await get_store_detail(params, storeId);
    setUserInfo(userInfo);
  };

  useEffect(() => {
    getDetailStore(storeId);
  }, []);

  const userInfos: ListType[] = [
    [
      { title: "이름(상호):", name: `${userInfo?.user.name} (${userInfo?.title})` },
      { title: "선정산 시작일:", name: userInfo?.ServiceInfo.firstEarlypayDate ?? "-" },
    ],
    [
      { title: "핸드폰번호:", name: userInfo?.user?.phone },
      { title: "사업자번호:", name: userInfo?.businessRegistrationNumber },
    ],
    [
      { title: "UTM", name: `${userInfo?.inflowPath} (${userInfo?.inflowMedium})` },
      { title: "업종/업태", name: `${userInfo?.businessItem} (${userInfo?.businessType})` },
    ],
    [
      { title: "서비스 타입", name: userInfo?.serviceType },
      { title: "카드커넥트", name: userInfo?.isCardconnectCompleted ? "연동" : "미연동" },
    ],
    [
      { title: "친구추천 코드", name: userInfo?.user.recommenderCode },
      { title: "매니저 전화번호", name: userInfo?.managerPhone ?? "-" },
    ],
  ];

  const serviceInfos: ListType[] = [
    [
      { title: "서비스 상태", name: userInfo?.ServiceInfo.serviceStatus },
      { title: "이용료 정보:", name: userInfo?.PricePolicy.title ?? "-" },
    ],
    [
      { title: "선정산 기준시:", name: `${userInfo?.ServiceInfo.settlementTimeOffset}시` },
      { title: "비고:", name: userInfo?.ServiceInfo.remark ?? "-" },
    ],
  ];

  return (
    <DetailUserInfoWrapper>
      <Text size={16} bold>
        가게 상세정보{" "}
        <Link
          style={{ color: "#eee" }}
          href={`https://www.admin.earlypay.co.kr/admin/stores/store/${storeId}/change/`}
          target="_blank"
        >
          (어드민 가게 이동)
        </Link>
      </Text>
      {userInfos.map((user, index) => {
        return (
          <Sections key={index}>
            <Row>
              <RowTitle>{user[0].title}</RowTitle>
              <RowContent>{user[0].name}</RowContent>
            </Row>
            <Row>
              <RowTitle>{user[1].title} </RowTitle>
              <RowContent>{user[1].name}</RowContent>
            </Row>
          </Sections>
        );
      })}
      <Text style={{ marginTop: "30px" }}>서비스 정보</Text>
      {serviceInfos.map((user, index) => {
        return (
          <Sections key={index}>
            <Row>
              <RowTitle>{user[0].title}</RowTitle>
              <RowContent>{user[0].name}</RowContent>
            </Row>
            <Row>
              <RowTitle>{user[1].title} </RowTitle>
              <RowContent>{user[1].name}</RowContent>
            </Row>
          </Sections>
        );
      })}
      <Text style={{ marginTop: "30px" }}>걔정 정보</Text>
      <Sections style={{ gridTemplateColumns: "1fr" }}>
        <Row style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
          <ColumnTitle>플랫폼</ColumnTitle>
          <ColumnTitle>아이디</ColumnTitle>
          <ColumnTitle>비밀번호</ColumnTitle>
          <ColumnTitle>사이트 이동</ColumnTitle>
        </Row>
      </Sections>
      {userInfo?.Accounts.map((account, index) => {
        return (
          <Sections key={index} style={{ gridTemplateColumns: "1fr" }}>
            <Row className="account-box" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
              <RowTitle>{account.platform}</RowTitle>
              <RowContent data-title={`${account.platform}-id`}>{account.identification}</RowContent>
              <RowContent data-title={`${account.platform}-pw`}>{account.password}</RowContent>
              <Link target="_blank" href={`${platformSiteURL[account.platform]}`}>
                이동
              </Link>
            </Row>
          </Sections>
        );
      })}
      <h6 style={{ marginTop: "30px" }}>플랫폼 연동 여부</h6>
      <Sections style={{ gridTemplateColumns: "1fr" }}>
        <Row style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
          <ColumnTitle>플랫폼</ColumnTitle>
          <ColumnTitle>연동여부</ColumnTitle>
          <ColumnTitle>활성여부</ColumnTitle>
          <ColumnTitle>최초연동</ColumnTitle>
        </Row>
      </Sections>
      {userInfo?.Cardconnects.map((account, index) => {
        const isConnect = account.isConnected;
        const isDisabled = !account.isDisabled;
        const firstConnectedAt = account.firstConnectedAt?.split("T")[0];

        return (
          <Sections key={index} style={{ gridTemplateColumns: "1fr" }}>
            <Row style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
              <RowTitle>{account.cardCompany}</RowTitle>
              <RowContent>
                <div>
                  {isConnect ? (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-yes.svg" />
                  ) : (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-no.svg" />
                  )}
                </div>
              </RowContent>
              <RowContent>
                <div>
                  {isDisabled ? (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-yes.svg" />
                  ) : (
                    <img src="https://earlypay-backend.s3.amazonaws.com/static/admin/img/icon-no.svg" />
                  )}
                </div>
              </RowContent>
              <RowContent>{firstConnectedAt ?? "-"}</RowContent>
            </Row>
          </Sections>
        );
      })}
    </DetailUserInfoWrapper>
  );
};

export default DetailUserInfo;
