import React, { useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";

import Button, { IconButton } from "@atlaskit/button/new";

import CrossIcon from "@atlaskit/icon/glyph/cross";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import EditorRemoveIcon from "@atlaskit/icon/glyph/editor/remove";

import { TimePicker } from "@atlaskit/datetime-picker";
import Lozenge from "@atlaskit/lozenge";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { RightPanel } from "@atlaskit/page-layout";
import { Flex, Grid, xcss } from "@atlaskit/primitives";
import { Box } from "@atlaskit/primitives";
import SectionMessage from "@atlaskit/section-message";
import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import Textfield from "@atlaskit/textfield";

import { usePutDepositStore } from "@/apis/deposit/putDepositStore";

interface SubmitHistoryValues {
  depositedAt: string;
  cardCompany: string;
  amountDeposit: number | null;
  trxType: "D" | "W";
  id?: number | null;
}

const SERVICE_TYPE = [
  { value: "기타", label: "기타" },
  { value: "배달의민족", label: "배달의민족" },
  { value: "요기요", label: "요기요" },
  { value: "쿠팡이츠", label: "쿠팡이츠" },
  { value: "비씨", label: "비씨" },
  { value: "국민", label: "국민" },
  { value: "하나", label: "하나" },
  { value: "삼성", label: "삼성" },
  { value: "현대", label: "현대" },
  { value: "롯데", label: "롯데" },
  { value: "신한", label: "신한" },
  { value: "농협", label: "농협" },
  { value: "우리", label: "우리" },
  { value: "얼리페이", label: "얼리페이" },
];

const MONEY_TYPE = [
  { value: "입금", label: <Lozenge appearance="success">입금</Lozenge> },
  { value: "출금", label: <Lozenge appearance="removed">출금</Lozenge> },
];

const HistoryPannel = ({ storeDetailData, todayTotalAmount, titleDate, depositDetailData, onClose, isLoading }) => {
  const [isEdit, setIsEdit] = useState<{ show: boolean; id: string }>({ show: false, id: "" });
  const [originDetailData, setOriginDetailData] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showIsDirtyModal, setShowIsDirtyModal] = useState<boolean>(false);
  const liveDepositAmount = originDetailData
    .filter(item => item.trxType === "입금")
    .reduce((sum, item) => {
      // amountDeposit 값을 처리
      const rawValue = item.amountDeposit;
      // 쉼표 제거 후 숫자로 변환, 변환 불가 시 0으로 처리
      const amount = parseFloat(typeof rawValue === "string" ? rawValue.replace(/,/g, "") : rawValue) || 0;
      return sum + amount;
    }, 0);
  const liveDiffrence = todayTotalAmount?.totalWithdraw - liveDepositAmount;

  const mutation = usePutDepositStore(storeDetailData.id);

  const now = new Date();
  const formattedToday = now.toISOString().split("T")[0];
  const formattedTitleDate = titleDate.replaceAll("-", ".");
  const todayByComma = formattedToday.replaceAll("-", ".");
  const canControl = formattedTitleDate === todayByComma;

  const formatDateTime = (dateTimeString: string): { total: string; date: string; time: string; nowTime: string } => {
    const date = new Date(dateTimeString);
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const nowTime = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;
    return {
      total: `${year}.${month}.${day} ${hours}:${minutes}`,
      date: `${year}.${month}.${day}`,
      time: `${hours}:${minutes}`,
      nowTime,
    };
  };

  const changeField = ({ idValue }) => {
    if (!canControl) return;
    setIsEdit({ show: true, id: idValue });
  };

  const updateField = (id, fieldName, value) => {
    setOriginDetailData(prevTransactions =>
      prevTransactions.map(transaction =>
        transaction.id === id ? { ...transaction, [fieldName]: value } : transaction,
      ),
    );
    setIsEdit({ show: false, id: "" });
  };

  const addNewDetailData = () => {
    const newId = Number(Math.random().toString().slice(2, 9));
    setOriginDetailData(prev => [
      ...prev,
      {
        id: newId,
        cardCompany: "입금사",
        amountDeposit: "금액 입력",
        trxType: "입금",
        depositedAt: new Date().toISOString(),
        isNew: true,
      },
    ]);

    setIsEdit({ show: true, id: `${newId}_입금사` });
  };

  const removeDetailData = (deleteId: string) => {
    setOriginDetailData(prev => prev.filter(item => item.id !== deleteId));
    setShowErrorMessage(false);
  };

  function convertToISOFormat(dateStr) {
    const [datePart, timePart] = dateStr.split(" ");
    const [year, month, day] = datePart.split(".").map((part, index) => {
      if (index === 0) return `20${part}`;
      return part.padStart(2, "0");
    });
    const [hours, minutes] = timePart.split(":").map(part => part.padStart(2, "0"));

    const isoDate = `${year}-${month}-${day}T${hours}:${minutes}:00+09:00`;
    return isoDate;
  }

  function handleKeyDown(event, id, fieldName, value) {
    if (event.key === "Escape" || event.code === "Escape") {
      setIsEdit({ show: false, id: "" });
    }
    if (event.key === "Enter" || event.code === "Enter") {
      updateField(id, fieldName, value);
    }
  }

  const validateData = () => {
    const errors = originDetailData.find(
      item =>
        item.trxType === "" ||
        item.cardCompany === "입금사" ||
        item.amountDeposit === "금액 입력" ||
        item.amountDeposit === "" ||
        item.depositedAt === "",
    )
      ? true
      : false;

    if (errors) {
      setShowErrorMessage(true);
      return false;
    }
    setShowErrorMessage(false);
    return true;
  };

  const onSubmit = useCallback(
    (data: SubmitHistoryValues[]) => {
      mutation.mutate(data);
    },
    [mutation],
  );

  const saveDetailData = () => {
    const submitData = originDetailData.map(item => {
      if (item.isNew || typeof item.amountDeposit === "string") {
        return {
          cardCompany: item.cardCompany,
          amountDeposit: Number(item.amountDeposit.replaceAll(",", "")),
          trxType: item.trxType,
          depositedAt: item.depositedAt,
        };
      }
      return item;
    }) as SubmitHistoryValues[];

    if (validateData()) {
      onSubmit(submitData);
      setShowErrorMessage(false);
    }
  };

  const resetDetailData = () => {
    setOriginDetailData(depositDetailData.results);
  };

  const closePannel = () => {
    const isDirty = JSON.stringify(originDetailData) !== JSON.stringify(depositDetailData?.results);
    if (isDirty) {
      setShowIsDirtyModal(true);
      return false;
    }
    onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closePannel();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (depositDetailData) {
      setOriginDetailData(depositDetailData.results);
    }
    setShowErrorMessage(false);
  }, [depositDetailData, titleDate]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (isEdit.id.includes("time") && event.target.className !== " css-bxfvr9") {
        setIsEdit({ show: false, id: "" });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEdit]);

  return (
    <RightPanel isFixed={true} width={430} testId="rightPannel">
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box style={{ color: "#626F86", fontSize: "14px", fontWeight: 500 }}>{storeDetailData.title}</Box>
        <IconButton
          icon={iconProps => <CrossIcon {...iconProps} size="medium" />}
          label="close"
          appearance="subtle"
          onClick={closePannel}
        />
      </Box>
      <Box style={{ fontSize: "24px", color: "#172B4D", fontWeight: "bold", paddingBottom: "16px" }}>
        {formattedTitleDate}
      </Box>
      <Box>
        {!canControl && (
          <Box>
            <SectionMessage appearance="information">
              <Box style={{ fontSize: "14px", color: "#172B4D" }}>
                조회일 기준 당일 내역만 수정 및 추가가 가능합니다.
              </Box>
            </SectionMessage>
          </Box>
        )}

        <Box style={{ display: "flex", border: "1px solid #091E4224", borderRadius: "3px", marginTop: "12px" }}>
          <Box style={{ width: "100%" }}>
            <Box style={{ backgroundColor: "#091E420F", padding: "8px 12px", fontSize: "14px", color: "#626F86" }}>
              실회수액
            </Box>
            <Box style={{ fontSize: "14px", color: "#172B4D", padding: "8px 12px" }}>
              {todayTotalAmount?.totalWithdraw?.toLocaleString() ?? "-"}
            </Box>
          </Box>
          <Box style={{ width: "100%" }}>
            <Box style={{ backgroundColor: "#091E420F", padding: "8px 12px", fontSize: "14px", color: "#626F86" }}>
              회수내역 총액
            </Box>
            <Box style={{ fontSize: "14px", color: "#172B4D", padding: "8px 12px" }}>
              {/* {todayTotalAmount?.totalDeposit?.toLocaleString() ?? "-"} */}
              {liveDepositAmount.toLocaleString() ?? "-"}
            </Box>
          </Box>
          <Box style={{ width: "100%" }}>
            <Box style={{ backgroundColor: "#091E420F", padding: "8px 12px", fontSize: "14px", color: "#626F86" }}>
              정산 차액
            </Box>
            <Box
              style={{
                fontSize: "14px",
                color: liveDiffrence < 0 ? "#AE2E24" : "#0045D6",
                padding: "8px 12px",
              }}
            >
              {liveDiffrence?.toLocaleString() ?? "-"}
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "26px",
            paddingBottom: "8px",
          }}
        >
          <Box style={{ color: "#172B4D", fontWeight: 500, fontSize: "14px" }}>입출금 상세내역</Box>
        </Box>

        {isLoading && (
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "20px" }}>
            <Spinner interactionName="load" label="Loading" size="large" />
          </Box>
        )}
        {showErrorMessage && (
          <Box style={{ paddingTop: "12px", paddingBottom: "8px" }}>
            <SectionMessage appearance="error">
              <Box>모든 항목을 빠짐없이 입력한 후 재시도해 주세요.</Box>
            </SectionMessage>
          </Box>
        )}

        {!isLoading && (
          <>
            {originDetailData.length === 0 ? (
              <Box
                style={{
                  color: "#172B4D",
                  fontSize: "14px",
                  padding: "48px 0",
                  textAlign: "center",
                  marginBottom: "18px",
                }}
              >
                입출금 내역이 없습니다.
              </Box>
            ) : (
              <Box
                style={{
                  border: "1px solid #091E4224",
                  borderBottom: 0,
                  borderRadius: "3px",
                  backgroundColor: canControl ? "#FFFFFF" : "#091E4208",
                }}
              >
                {originDetailData.map((list, index) => (
                  <Box
                    key={`${list.id}_${list.cardCompany}_${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #091E4224",
                      position: "relative",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box style={{ position: "relative", width: "90px" }}>
                      {(!isEdit.show || (isEdit.show && !(isEdit.id === `${list.id}_${list.cardCompany}`))) && (
                        <Box
                          style={{
                            color: list?.cardCompany === "입금사" ? "#8590A2" : canControl ? "#172B4D" : "#091E424F",
                            fontSize: "14px",
                            padding: "8px",
                            width: "87px",
                          }}
                          onClick={() => changeField({ idValue: `${list.id}_${list.cardCompany}` })}
                        >
                          {list?.cardCompany}
                        </Box>
                      )}
                      {isEdit.show && isEdit.id === `${list.id}_${list.cardCompany}` && (
                        <Box
                          style={{
                            position: "absolute",
                            top: -16,
                            left: 0,
                            width: "140px",
                            zIndex: 1,
                          }}
                        >
                          <Select
                            inputId="single-select"
                            className="single-select"
                            options={SERVICE_TYPE}
                            placeholder="입금사"
                            appearance="subtle"
                            defaultValue={SERVICE_TYPE.find(option => option.value === list?.cardCompany)}
                            autoFocus
                            openMenuOnFocus
                            spacing="compact"
                            onChange={newValue => {
                              updateField(list.id, "cardCompany", newValue.value);
                              changeField({ idValue: `${list.id}_${list.amountDeposit}` });
                            }}
                            onBlur={() => setIsEdit({ show: false, id: "" })}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box style={{ position: "relative", width: "100px" }}>
                      {(!isEdit.show || (isEdit.show && !(isEdit.id === `${list.id}_${list.amountDeposit}`))) && (
                        <Box
                          style={{
                            color:
                              list?.amountDeposit === "금액 입력" ? "#8590A2" : canControl ? "#172B4D" : "#091E424F",
                            fontSize: "14px",
                            padding: "8px",
                            width: "90px",
                          }}
                          onClick={() => changeField({ idValue: `${list.id}_${list.amountDeposit}` })}
                        >
                          {list.amountDeposit.toLocaleString()}
                        </Box>
                      )}
                      {isEdit.show && isEdit.id === `${list.id}_${list.amountDeposit}` && (
                        <Box
                          style={{
                            position: "absolute",
                            top: -16,
                            left: 0,
                            width: "140px",
                            zIndex: 1,
                          }}
                        >
                          <Textfield
                            defaultValue={list.amountDeposit === "금액 입력" ? "" : list.amountDeposit.toLocaleString()}
                            autoFocus
                            isCompact
                            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                              updateField(
                                list.id,
                                "amountDeposit",
                                event.target.value !== "금액 입력" && event.target.value !== ""
                                  ? Number(event.target.value.replaceAll(",", "")).toLocaleString("ko-KR")
                                  : "금액 입력",
                              );
                            }}
                            onKeyDown={event => {
                              const target = event.target as HTMLInputElement;
                              handleKeyDown(
                                event,
                                list.id,
                                "amountDeposit",
                                Number(target.value.replaceAll(",", "")).toLocaleString("ko-KR"),
                              );
                            }}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box style={{ position: "relative", width: "58px" }}>
                      {(!isEdit.show || (isEdit.show && !(isEdit.id === `${list.id}_${list.trxType}`))) && (
                        <Box
                          style={{ padding: "8px" }}
                          onClick={() => changeField({ idValue: `${list.id}_${list.trxType}` })}
                        >
                          <Box
                            style={{
                              backgroundColor: list.trxType === "입금" ? "#BAF3DB" : "#FFD5D2",
                              color: list.trxType === "입금" ? "#216E4E" : "#AE2E24",
                              padding: "3px 4px",
                              borderRadius: "3px",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {list.trxType}
                          </Box>
                        </Box>
                      )}
                      {isEdit.show && isEdit.id === `${list.id}_${list.trxType}` && (
                        <Box
                          style={{
                            position: "absolute",
                            top: -16,
                            left: 0,
                            width: "140px",
                            zIndex: 1,
                          }}
                        >
                          <Select
                            inputId="single-select-example"
                            className="single-select"
                            options={MONEY_TYPE}
                            placeholder="입금/출금"
                            appearance="subtle"
                            defaultValue={MONEY_TYPE.find(option => option.value === list?.trxType)}
                            autoFocus
                            openMenuOnFocus
                            spacing="compact"
                            onChange={newValue => updateField(list.id, "trxType", newValue.value)}
                            onBlur={() => setIsEdit({ show: false, id: "" })}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box style={{ position: "relative", width: "74px" }}>
                      {(!isEdit.show || (isEdit.show && !(isEdit.id === `${list.id}_${list.depositedAt}_time`))) && (
                        <Box
                          style={{
                            display: "flex",
                            gap: "4px",
                            color: canControl ? "#172B4D" : "#091E424F",
                            fontSize: "14px",
                            padding: "8px",
                          }}
                        >
                          {/* <Box>{formatDateTime(list.depositedAt).date}</Box> */}
                          <Box onClick={() => changeField({ idValue: `${list.id}_${list.depositedAt}_time` })}>
                            {formatDateTime(list.depositedAt).time}
                          </Box>
                        </Box>
                      )}
                      {isEdit.show && isEdit.id === `${list.id}_${list.depositedAt}_time` && (
                        <TimeBox id="tttttt">
                          {/* <Box style={{ fontSize: "14px" }}>{formatDateTime(list.depositedAt).date}</Box> */}
                          {/* <DateTimePicker
                            id="datetime"
                            datePickerProps={{
                              label: "Appointment date",
                              isDisabled: true,
                              defaultValue: formatDateTime(list.depositedAt).date,
                              placeholder: formatDateTime(list.depositedAt).date,
                              spacing: "compact",
                              locale: "ko",
                              onBlur: () => setIsEdit({ show: false, id: "" }),
                            }}
                            timePickerProps={{
                              label: "Appointment time",
                              isDisabled: false,
                              spacing: "compact",
                              defaultValue: formatDateTime(list.depositedAt).time,
                              placeholder: formatDateTime(list.depositedAt).time,
                              timeFormat: "HH:mm",
                              onChange: value =>
                                updateField(
                                  list.id,
                                  "depositedAt",
                                  convertToISOFormat(`${formatDateTime(list.depositedAt).date} ${value}`),
                                ),
                              timeIsEditable: true,
                              onBlur: () => setIsEdit({ show: false, id: "" }),
                            }}
                            onBlur={() => setIsEdit({ show: false, id: "" })}
                          /> */}
                          <TimePicker
                            testId="pannel-timepicker"
                            autoFocus
                            id="timepicker-editable-time"
                            label="Appointment time"
                            timeIsEditable
                            //isDisabled={false}
                            spacing="compact"
                            defaultValue={
                              formatDateTime(list.depositedAt).time ?? formatDateTime(list.depositedAt).nowTime
                            }
                            placeholder={
                              formatDateTime(list.depositedAt).time ?? formatDateTime(list.depositedAt).nowTime
                            }
                            timeFormat="HH:mm"
                            onChange={value => {
                              updateField(
                                list.id,
                                "depositedAt",
                                convertToISOFormat(
                                  value === ""
                                    ? `${formatDateTime(list.depositedAt).date} ${
                                        formatDateTime(list.depositedAt).nowTime
                                      }`
                                    : `${formatDateTime(list.depositedAt).date} ${value}`,
                                ),
                              );
                            }}
                            hideIcon
                          />
                        </TimeBox>
                      )}
                    </Box>

                    {canControl && (
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          icon={iconProps => <EditorRemoveIcon {...iconProps} primaryColor="#44546F" />}
                          label="remove"
                          appearance="subtle"
                          onClick={() => removeDetailData(list.id)}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
        <Box
          style={{
            paddingTop: "18px",
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          <Button iconBefore={EditorAddIcon} onClick={addNewDetailData} isDisabled={!canControl}>
            입출금 내역 추가
          </Button>
          <Box>
            <Button appearance="subtle" onClick={resetDetailData} isDisabled={!canControl}>
              초기화
            </Button>
            <Button appearance="primary" onClick={saveDetailData} isDisabled={!canControl}>
              저장
            </Button>
          </Box>
        </Box>
      </Box>
      <ModalTransition>
        {showIsDirtyModal && (
          <Modal onClose={() => setShowIsDirtyModal(false)}>
            <ModalHeader>
              <Grid gap="space.200" templateAreas={["title close"]} xcss={gridStyles}>
                <Flex xcss={closeContainerStyles} justifyContent="end">
                  <IconButton
                    appearance="subtle"
                    icon={CrossIcon}
                    label="Close Modal"
                    onClick={() => setShowIsDirtyModal(false)}
                  />
                </Flex>
                <Flex xcss={titleContainerStyles} justifyContent="start">
                  <ModalTitle appearance="warning">
                    <Box style={{ color: "#172B4D", fontSize: "20px" }}>
                      작성 중인 입출금 추가 내역이 있습니다.
                      <br />
                      저장하지 않고 나가시겠습니까?
                    </Box>
                  </ModalTitle>
                </Flex>
              </Grid>
            </ModalHeader>
            <ModalBody>
              <Box style={{ color: "#44546F", fontSize: "14px" }}>
                저장하지 않고 페이지를 벗어날 경우 지금까지 작성한 내용이 사라집니다.
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="warning"
                autoFocus
                onClick={() => {
                  onClose();
                  setShowIsDirtyModal(false);
                }}
              >
                나가기
              </Button>
              <Button
                appearance="subtle"
                onClick={() => {
                  setShowIsDirtyModal(false);
                }}
              >
                취소
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </RightPanel>
  );
};

export default HistoryPannel;

const gridStyles = xcss({
  width: "100%",
});

const closeContainerStyles = xcss({
  gridArea: "close",
});

const titleContainerStyles = xcss({
  gridArea: "title",
});

const TimeBox = styled(Box)`
  position: absolute;
  top: -17;
  left: 0;
  width: 77px;
  zindex: 1;
  display: flex;
  & > div {
    width: 100%;
  }
`;
