import { useMutation, useQueryClient } from "react-query";

import { StoreDetailInfoProps } from "@/types/storeType";

import { instance } from "../instance";

// 특정 날짜에 따른 가게의 입출금 내역을 수정합니다.
const patch_store_detail = async (store_id: number, body: StoreDetailInfoProps) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/admin/stores/${store_id}`,
    data: body,
  });

  return data;
};

export const useEditStoreDetail = (store_id: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["EditStoreDetail", store_id],
    (data: StoreDetailInfoProps) => patch_store_detail(store_id, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        queryClient.invalidateQueries(["StoreDetail"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
