import styled from "styled-components";

import { FlexBox, Text } from "@/components/atoms";

import theme from "src/styles/theme";

export const TabGrid = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleText = styled(Text)`
  font-size: 15px;
  color: ${theme.color.black_10};
  font-weight: 500 !important;
  margin-top: 10px;

  &:nth-of-type(0) {
    margin-top: 0px;
  }
`;

export const SubTitleText = styled(Text)`
  font-size: 13px;
  color: ${theme.color.black_20};
  font-weight: 500 !important;
  margin-left: 4px;
  margin-bottom: 5px;
`;

export const PriceText = styled(Text)`
  font-size: 15px;
  text-decoration: ${(props: { [key: string]: string }) => props._underline && "underline"};
  color: ${theme.color.black_10};
  font-weight: 500 !important;
  margin-top: 20px;
`;

export const CircleText = styled.li`
  color: #505050;
  padding-left: 8px;
  margin-bottom: 2px;
  font-size: 13px;

  &::marker {
    color: #bbb;
  }
`;

export const SubPriceText = styled(Text)`
  font-size: 13px;
  color: #505050;
  font-weight: 400;
  padding-left: 16px;
  margin-bottom: 2px;
  text-decoration: ${(props: { [key: string]: string }) => props._underline && "underline"};
  font-size: ${(props: { [key: string]: string }) => props._earlypayExceptType && "12px"};
  color: ${(props: { [key: string]: string }) => props._earlypayExceptType && "#909090"};
`;
