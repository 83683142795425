import { useState } from "react";

import { DepositDetailProps } from "@/types/depositType";

import { getDate, getTime } from "@/utils/getFormattedDate";

export const useDepositModal = () => {
  const [isVisibleAddDepositModal, setIsVisibleAddDepositModal] = useState(false);
  const [isVisibleEditDepositModal, setIsVisibleEditDepositModal] = useState(false);
  const [isVisibleDeleteDepositModal, setIsVisibleDeleteDepositModal] = useState(false);
  const [selectedDepositDetail, setSelectedDepositDetail] = useState<DepositDetailProps>({
    id: 0,
    depositedAt: "",
    cardCompany: "기타",
    amountDeposit: 0,
    trxType: "입금",
  });

  /** 입출금 내역 수정 모달 호출 함수 */
  const openEditDepositModal = (item: DepositDetailProps) => {
    const formattedDateTime = `${getDate(item.depositedAt, "yyyy-mm-dd")}T${getTime(item.depositedAt)}`;
    setSelectedDepositDetail({
      ...item,
      depositedAt: formattedDateTime,
    });
    setIsVisibleEditDepositModal(true);
  };

  /** 입출금 내역 수정 모달 닫기 함수 */
  const closeEditDepositModal = () => {
    setIsVisibleEditDepositModal(false);
  };

  /** 입출금 내역 추가 모달 호출 함수 */
  const openAddDepositModal = () => {
    setIsVisibleAddDepositModal(true);
  };

  /** 입출금 내역 삭제 모달 호출 함수 */
  const openDeleteDepositModal = (item: DepositDetailProps) => {
    setIsVisibleDeleteDepositModal(true);
    setSelectedDepositDetail(item);
  };

  /** 입출금 내역 수정 모달 닫기 함수 */
  const closeAddDepositModal = () => {
    setIsVisibleAddDepositModal(false);
  };

  /** 입출금 내역 삭제 모달 닫기 함수 */
  const closeDeleteDepositModal = () => {
    setIsVisibleDeleteDepositModal(false);
  };

  return {
    openEditDepositModal,
    closeEditDepositModal,
    openAddDepositModal,
    closeAddDepositModal,
    openDeleteDepositModal,
    closeDeleteDepositModal,
    isVisibleDeleteDepositModal,
    isVisibleAddDepositModal,
    isVisibleEditDepositModal,
    selectedDepositDetail,
  };
};
