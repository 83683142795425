import React from "react";

import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";

import "src/styles/global.css";
import "src/styles/index.scss";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
