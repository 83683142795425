import React from "react";

import styled from "styled-components";

const Logo = () => {
  return (
    <EarlyPayLogo>
      <Wave />
      <Wave_2 />
    </EarlyPayLogo>
  );
};

export default Logo;

const EarlyPayLogo = styled.div`
  border-radius: 0% 40% 30% 69% / 0% 56% 43% 100%;
  width: 86px;
  height: 55px;
  margin-right: 15px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  clip-path: polygon(
    5% 0,
    29% 64%,
    40% 84%,
    48% 93%,
    56% 98%,
    65% 100%,
    60% 77%,
    59% 35%,
    79% 35%,
    79% 68%,
    50% 68%,
    60% 100%,
    86% 100%,
    99% 100%,
    100% 85%,
    100% 5%,
    100% 0%
  );
  overflow: hidden;
`;

const Wave = styled.div`
  width: 150px;
  height: 150px;
  background: #ccc;
  position: absolute;
  border-radius: 40%;
  background: linear-gradient(180deg, #2a45d2 0%, #253aa9 45.31%, #602798 100%);
  animation: rotate 3000ms infinite;

  @keyframes rotate {
    to {
      transform: rotate(0deg);
      top: 00%;
    }
    from {
      transform: rotate(360deg);
      top: 100%;
    }
  }
`;

const Wave_2 = styled.div`
  width: 110px;
  height: 120px;
  background: #ccc;
  position: absolute;
  border-radius: 30%;
  background: linear-gradient(180deg, #2a45d2 0%, #253aa9 45.31%, #602798 100%);
  animation: rotate 3000ms infinite;
  left: -30px;
  @keyframes rotate {
    to {
      transform: rotate(20deg);
      top: -10%;
    }
    from {
      transform: rotate(380deg);
      top: 110%;
    }
  }
`;
