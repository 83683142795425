import React from "react";

// chart
import ReactApexChart from "react-apexcharts";

// type
import { Height, LineLabels, LineSeries, Option } from "src/types/graphType";

interface BarChartInterface {
  _series: LineSeries;
  _labels: LineLabels;
  _height?: Height;
  _column?: boolean;
  _index?: boolean;
}

const BarChart = ({
  _series = [
    {
      name: "default",
      data: [1],
    },
  ],
  _labels = ["default"],
  _height = "100%",
  _column = true,
  _index = true,
}: BarChartInterface) => {
  const maxNum: number[] = [];
  _series.map(x => maxNum.push(...x.data));
  const options: Option = {
    theme: { palette: "palette3" },
    chart: {
      type: "line",
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    colors: ["#0078d4"],
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: _column,
      },
    },

    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: _column ? ["#fff"] : ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [..._labels],
    },
    yaxis: {
      min: 0,
      max: Math.max(...maxNum) + Math.max(...maxNum) * 0.1,
      labels: {
        maxWidth: 80,
        align: "left",
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 35,
      fontSize: "15px",
      fontWeight: 300,
      show: _index,
    },
  };

  const series = [..._series];

  return (
    <>
      <ReactApexChart options={options} series={series} type="bar" height={_height} />
    </>
  );
};

export default BarChart;
