import React from "react";

import { Control, Controller } from "react-hook-form";

import Button from "@atlaskit/button/new";

import { Checkbox } from "@atlaskit/checkbox";
import { Box } from "@atlaskit/primitives";

import { VanPlatformProps } from "@/types/storeType";

import { ConnectSalesWrapper, ToggleWrapper } from "@/components/features/settlement/StoreInfoBox/index.styled";

export interface ConnectedPlatformListProps {
  fields: VanPlatformProps[];
  control: Control<{
    serviceStatus: "A" | "WPNS" | "WP" | "P" | "PC" | "TP" | "TC" | "T";
    platforms: VanPlatformProps[];
  }>;
  isCloseService: boolean;
  isDisabledSave: boolean;
}

export const ConnectedPlatformList: React.FC<ConnectedPlatformListProps> = ({
  fields,
  control,
  isCloseService,
  isDisabledSave,
}) => {
  const vanPlatformFields = fields.filter(
    item => item.platform !== "쿠팡이츠" && item.platform !== "요기요" && item.platform !== "배달의민족",
  );
  const deliveryPlatformFields = fields.filter(
    item => item.platform === "요기요" || item.platform === "배달의민족" || item.platform === "쿠팡이츠",
  );

  return (
    <ConnectSalesWrapper>
      {/** 연동 배달플랫폼 토글 스위치 */}
      <ToggleWrapper>
        <Box style={{ flexShrink: 0 }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            {deliveryPlatformFields.map((item, index) => (
              <Controller
                key={index}
                control={control}
                name={`platforms.${index + vanPlatformFields.length}.isDisabled`}
                render={({ field: { onChange, value, ref } }) => (
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      id={`toggle-label-${item.platform}`}
                      ref={ref}
                      defaultChecked={item.isConnected}
                      isChecked={!value && !isCloseService && item.isConnected}
                      isDisabled={item.isDisabled && !item.isConnected}
                      onChange={() => !isCloseService && item.isConnected && onChange(!value)}
                    />
                    <Box>{item.platform}</Box>
                    {/* <CustomToggle
                id={`toggle-label-${item.platform}`}
                innerRef={ref}
                size="sm"
                hideLabel
                labelText={item.platform}
                labelA="Off"
                labelB="On"
                isConnected={item.isConnected}
                toggled={!value && !isCloseService && item.isConnected}
                onClick={() => !isCloseService && item.isConnected && onChange(!value)}
              /> */}
                  </Box>
                )}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {vanPlatformFields.map((item, index) => {
              return (
                <Controller
                  key={index}
                  control={control}
                  name={`platforms.${index}.isDisabled`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        id={`toggle-label-${item.platform}`}
                        ref={ref}
                        defaultChecked={item.isConnected}
                        isChecked={!value && !isCloseService && item.isConnected}
                        isDisabled={item.isDisabled && !item.isConnected}
                        onChange={() => !isCloseService && item.isConnected && onChange(!value)}
                      />
                      <Box style={{ color: item.isConnected ? "#172B4D" : "#AE2E24" }}>{item.platform}</Box>
                      {/* <CustomToggle
                    id={`toggle-label-${item.platform}`}
                    innerRef={ref}
                    size="sm"
                    hideLabel
                    labelText={item.platform}
                    labelA="Off"
                    labelB="On"
                    isConnected={item.isConnected}
                    toggled={!value && !isCloseService && item.isConnected}
                    onClick={() => !isCloseService && item.isConnected && onChange(!value)}
                  /> */}
                    </Box>
                  )}
                />
              );
            })}
          </Box>
        </Box>
        <Box style={{ display: "flex", alignItems: "flex-end" }}>
          <Button appearance="primary" type="submit" spacing="compact" isDisabled={isDisabledSave}>
            저장
          </Button>
        </Box>
      </ToggleWrapper>
    </ConnectSalesWrapper>
  );
};

export default ConnectedPlatformList;
