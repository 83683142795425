import React from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";

import Toast from "@/components/elements/Toast";
import { AdminDetail, AdminStore, Earlypay, Funnel, Home, Login, SettlementDetail, SettlementStore } from "@/pages";

import AdminProvider from "src/pages/Admin/adminContext";

import { FlagProvider } from "./components/block/Flag";
import { initializeServices } from "./init";
import MainLayout from "./layouts/MainLayout";
import SettlementLayout from "./layouts/SettlementLayout";

function App() {
  initializeServices();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RecoilRoot>
        <FlagProvider>
          <Toast />
          <Router>
            <Routes>
              <Route path="" element={<MainLayout />}>
                <Route index element={<Navigate to="/settlement" replace />} />
                <Route path="legacy/dashboard" element={<Home />} />
                <Route path="funnel" element={<Funnel />} />
                <Route path="admin">
                  <Route
                    path="store"
                    element={
                      <AdminProvider>
                        <AdminStore />
                      </AdminProvider>
                    }
                  />
                  <Route
                    path="earlypay"
                    element={
                      <AdminProvider>
                        <Earlypay />
                      </AdminProvider>
                    }
                  />
                  <Route
                    path="detail"
                    element={
                      <AdminProvider>
                        <AdminDetail />
                      </AdminProvider>
                    }
                  />
                </Route>
                <Route path="" element={<SettlementLayout />}>
                  <Route path="/settlement" element={<SettlementStore />} />
                  <Route path="/settlement/detail/:id" element={<SettlementDetail />} />
                </Route>
              </Route>
              <Route path="login" element={<Login />} />
            </Routes>
          </Router>
        </FlagProvider>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
