import { useQuery } from "react-query";

import { instance } from "../instance";

interface PuzzlingProps {
  status: "NOT_STARTED" | "IN_PROGRESS" | "DONE" | "FAILED";
}
const getPuzzling = async () => {
  const { data } = await instance<PuzzlingProps>({
    method: "get",
    url: "/v2/admin/tasks/puzzling",
  });

  return data;
};

export const useGetPuzzling = () => {
  const { data, isLoading, refetch } = useQuery<PuzzlingProps, Error>(["GetPuzzling"], () => getPuzzling());
  return {
    data,
    isLoading,
    refetch,
  };
};
