import React, { useEffect, useState } from "react";

import { get_earlypay_summary, get_sale_summary } from "src/apis/admin";
import { HeadingCard } from "src/components/block";
import type { DetailPropsInterface } from "src/pages/Admin/Detail";
import { PLATFORM_NAME, SaleKeyType } from "src/types/commonType";
import type { DepositObjectType } from "src/types/depositType";

import AnotherEarlypay from "./AnotherEarlypay";
import { CircleText, PriceText, SubPriceText, TabGrid, TitleText } from "./index.styled";

const DailyEarlypay = ({ _storeId, _date }: DetailPropsInterface) => {
  const [earlypays, setEarlypays] = useState<{ [paltform in SaleKeyType]: DepositObjectType }>({
    card: {},
    baemin: {},
    yogiyo: {},
    coupang: {},
  });

  const [fees, setFees] = useState({
    fee: 0,
    interest: 0,
    totalFee: 0,
  });

  const calculateDeposit =
    earlypays.card.deposit +
    earlypays.baemin.deposit +
    earlypays.yogiyo.deposit +
    earlypays.coupang.deposit -
    fees.totalFee;

  const getEarlypay = async () => {
    const date = new Date(_date);
    date.setDate(date.getDate() - 1);
    const soldAt = date.toISOString().split("T")[0];

    const depositPromise = get_earlypay_summary(_date, _storeId);
    const salePromise = get_sale_summary({ sold_at: soldAt }, _storeId);
    const deposit = await depositPromise;
    const sale = await salePromise;

    /** 배달플랫폼 카드 정산 정보 */
    const baeminMeetCard = sale.baemin[soldAt]?.meetCard ?? 0;
    const baeminMeetCardCount = sale.baemin[soldAt]?.meetCardCount ?? 0;
    const yogiyoMeetCard = sale.yogiyo[soldAt]?.meetCard ?? 0;
    const yogiyoMeetCardCount = sale.yogiyo[soldAt]?.meetCardCount ?? 0;
    /** 카드 정산 정보 */
    const totalCardSale = sale.card[soldAt]?.sales ?? 0;
    const totalCardSaleCount = sale.card[soldAt]?.salesCount ?? 0;
    const cardSale = totalCardSale - baeminMeetCard - yogiyoMeetCard;
    const cardSaleCount = totalCardSaleCount - baeminMeetCardCount - yogiyoMeetCardCount;
    const cardFee = deposit.card[_date]?.fee ?? 0;
    const cardDeposit = deposit.card[_date]?.deposit;
    const cardDepositCount = deposit.card[_date]?.depositCount ?? 0;
    /** 배달의민족 정산 정보 */
    const baeminTotalSale = sale.baemin[soldAt]?.totalSales ?? 0;
    const baeminTotalSaleCount = sale.baemin[soldAt]?.totalSalesCount ?? 0;
    const baeminMeetCash = sale.baemin[soldAt]?.meetCash ?? 0;
    const baeminMeetCashCount = sale.baemin[soldAt]?.meetCashCount ?? 0;
    const baeminCeoDc = deposit.baemin[_date]?.ceoDc;
    const baeminFee = deposit.baemin[_date]?.fee;
    const baeminDeposit = deposit.baemin[_date]?.deposit;
    const baeminDepositCount = baeminTotalSaleCount - baeminMeetCashCount - baeminMeetCardCount;
    /** 요기요 정산 정보 */
    const yogiyoTotalSale = sale.yogiyo[soldAt]?.totalSales ?? 0;
    const yogiyoTotalSaleCount = sale.yogiyo[soldAt]?.totalSalesCount ?? 0;
    const yogiyoMeetCash = sale.yogiyo[soldAt]?.meetCash ?? 0;
    const yogiyoMeetCashCount = sale.yogiyo[soldAt]?.meetCashCount ?? 0;
    const yogiyoCeoDc = deposit.yogiyo[_date]?.ceoDc;
    const yogiyoAgentFee = deposit.yogiyo[_date]?.deliveryAgentFee;
    const yogiyoFee = deposit.yogiyo[_date]?.fee;
    const yogiyoDeposit = deposit.yogiyo[_date]?.deposit;
    const yogiyoDepositCount = yogiyoTotalSaleCount - yogiyoMeetCashCount - yogiyoMeetCardCount;
    /** 쿠팡이츠 정산 정보 */
    const coupangTotalSale = sale.coupang[soldAt]?.totalSales ?? 0;
    const coupangTotalSaleCount = sale.coupang[soldAt]?.totalSalesCount ?? 0;
    const coupangFee = deposit.coupang[_date]?.fee;
    const coupangDeposit = deposit.coupang[_date]?.deposit;
    const coupangAgentFee = deposit.coupang[_date]?.deliveryAgentFee;
    const coupangCeoDc = deposit.coupang[_date]?.ceoDc;
    const coupangDepositCount = coupangTotalSaleCount;
    /** 얼리페이 차감금 */
    const earlypayFee = deposit.earlypayServiceFee[_date]?.fee ?? 0;
    const earlypayInterestFee = deposit.earlypayServiceFee[_date]?.interest ?? 0;

    setFees({
      fee: earlypayFee,
      interest: earlypayInterestFee,
      totalFee: earlypayFee + earlypayInterestFee,
    });
    setEarlypays({
      card: {
        sales: cardSale < 0 ? totalCardSale : cardSale,
        salesCount: cardSaleCount < 0 ? totalCardSaleCount : cardSaleCount,
        meetBaemin: baeminMeetCard,
        meetBaeminCount: baeminMeetCardCount,
        meetYogiyo: yogiyoMeetCard,
        meetYogiyoCount: yogiyoMeetCardCount,
        deposit: cardDeposit,
        fee: cardFee,
        depositCount: cardDepositCount,
      },
      baemin: {
        sales: baeminTotalSale,
        salesCount: baeminTotalSaleCount,
        meetCard: baeminMeetCard,
        meetCardCount: baeminMeetCardCount,
        meetCash: baeminMeetCash,
        meetCashCount: baeminMeetCashCount,
        ceoDc: baeminCeoDc,
        fee: baeminFee,
        deposit: baeminDeposit,
        depositCount: baeminDepositCount,
      },
      yogiyo: {
        sales: yogiyoTotalSale,
        salesCount: yogiyoTotalSaleCount,
        meetCard: yogiyoMeetCard,
        meetCardCount: yogiyoMeetCardCount,
        meetCash: yogiyoMeetCash,
        meetCashCount: yogiyoMeetCashCount,
        ceoDc: yogiyoCeoDc,
        deliveryAgentFee: yogiyoAgentFee,
        fee: yogiyoFee,
        deposit: yogiyoDeposit,
        depositCount: yogiyoDepositCount,
      },
      coupang: {
        sales: coupangTotalSale,
        salesCount: coupangTotalSaleCount,
        ceoDc: coupangCeoDc,
        deliveryAgentFee: coupangAgentFee,
        fee: coupangFee,
        deposit: coupangDeposit,
        depositCount: coupangDepositCount,
      },
    });
  };

  const checkValue = (value: number | undefined) => {
    if (typeof value === "number") return true;
    return false;
  };

  useEffect(() => {
    getEarlypay();
  }, []);

  return (
    <HeadingCard _title="선정산 내역">
      <div style={{ marginTop: "-10px" }}>
        {Object.values(earlypays).map((platform, idx) => {
          const platformName = Object.keys(earlypays)[idx] as SaleKeyType;

          return (
            <div key={idx}>
              <TabGrid>
                <TitleText>
                  {PLATFORM_NAME[platformName]} 정산금 ({platform.depositCount}건)
                </TitleText>
                <PriceText>{platform.deposit?.toLocaleString()} 원</PriceText>
              </TabGrid>

              {/* 총매출 */}
              {checkValue(platform.sales) && (
                <TabGrid>
                  <CircleText>
                    {PLATFORM_NAME[platformName]} 매출 ({platform.salesCount}건)
                  </CircleText>
                  <SubPriceText>{platform.sales?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {checkValue(platform.meetBaemin) && (
                <TabGrid>
                  <CircleText>배민 만나서 카드 매출 ({platform.meetBaeminCount}건)</CircleText>
                  <SubPriceText>{platform.meetBaemin?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {checkValue(platform.meetYogiyo) && (
                <TabGrid>
                  <CircleText>요기요 만나서 카드 매출 ({platform.meetYogiyoCount}건)</CircleText>
                  <SubPriceText>{platform.meetYogiyo?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {/* 만나서결제 차감금액 */}
              {checkValue(platform.meetCard) && (
                <TabGrid>
                  <CircleText>만나서 카드매출 ({platform.meetCardCount}건)</CircleText>
                  <SubPriceText>-{platform.meetCard?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {checkValue(platform.meetCash) && (
                <TabGrid>
                  <CircleText>만나서 현금매출 ({platform.meetCashCount}건)</CircleText>
                  <SubPriceText>-{platform.meetCash?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {/* 수수료 */}
              {checkValue(platform.ceoDc) && (
                <TabGrid>
                  <CircleText>사장님 자체할인</CircleText>
                  <SubPriceText>-{platform.ceoDc?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {checkValue(platform.deliveryAgentFee) && (
                <TabGrid>
                  <CircleText>배달중개이용료</CircleText>
                  <SubPriceText>-{platform.deliveryAgentFee?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
              {checkValue(platform.fee) && (
                <TabGrid>
                  <CircleText>{PLATFORM_NAME[platformName]}수수료</CircleText>
                  <SubPriceText>-{platform.fee?.toLocaleString()} 원</SubPriceText>
                </TabGrid>
              )}
            </div>
          );
        })}
        <TabGrid>
          <TitleText>차감금</TitleText>
          <PriceText>-{fees.totalFee} 원</PriceText>
        </TabGrid>
        <div>
          <TabGrid>
            <CircleText>얼리페이 이용료</CircleText>
            <SubPriceText>-{fees.fee} 원</SubPriceText>
          </TabGrid>
          <TabGrid>
            <CircleText>선정산 금융수수료</CircleText>
            <SubPriceText>-{fees.interest} 원</SubPriceText>
          </TabGrid>
        </div>
        <AnotherEarlypay _storeId={_storeId} _date={_date} _calculateDeposit={calculateDeposit} />
      </div>
    </HeadingCard>
  );
};

export default DailyEarlypay;
