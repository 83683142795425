import { useMutation, useQueryClient } from "react-query";

import { DepositDetailProps } from "@/types/depositType";

import { instance } from "../instance";

// 특정 날짜에 따른 가게의 입출금 내역을 수정합니다.
const patch_deposit_detail = async (deposit_id: number, body: DepositDetailProps) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/admin/deposits/${deposit_id}`,
    data: body,
  });

  return data;
};

export const useEditDepositDetail = (deposit_id: number) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ["EditDepositDetail", deposit_id],
    (data: DepositDetailProps) => patch_deposit_detail(deposit_id, data),
    {
      onSuccess: () => {
        console.log("onSuccess");
        queryClient.invalidateQueries(["DepositDetail"]);
        queryClient.invalidateQueries(["DepositOverview"]);
      },
      onError: error => {
        console.log("onError", error);
      },
    },
  );

  return mutation;
};
