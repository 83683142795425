import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";

import { StoreDetailProps } from "@/types/storeType";

import { storeDetailState } from "@/recoil/stores/atoms";

import { instance } from "../instance";

// 가게 상세 정보를 조회합니다
const get_store_detail = async (store_id: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/admin/stores/${store_id}`,
  });

  return data;
};

export const useGetStoreDetail = (store_id: number) => {
  const setStoreDetail = useSetRecoilState(storeDetailState);

  return useQuery<StoreDetailProps, Error>(["StoreDetail", store_id], () => get_store_detail(store_id), {
    enabled: !!store_id,
    onSuccess: (data: StoreDetailProps) => setStoreDetail(data),
  });
};
