import React from "react";

import { useMutation, useQueryClient } from "react-query";

import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";

import { useFlag } from "@/components/block/Flag";

import { instance } from "../instance";

const postStoreMemo = async (storeId: number, memo: string) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/admin/stores/${storeId}/comments`,
    data: {
      comment: memo,
    },
  });

  return data;
};

export const usePostStoreMemo = (storeId: number) => {
  const { addFlag } = useFlag();
  const queryClient = useQueryClient();
  const mutation = useMutation(["postStoreMemo", storeId], (memo: string) => postStoreMemo(storeId, memo), {
    onSuccess: () => {
      console.log("onSuccess");
      addFlag({
        title: "메모가 추가 되었습니다.",
        icon: React.createElement(CheckCircleIcon, {
          primaryColor: "#22A06B",
          secondaryColor: "#FFFFFF",
          label: "Success",
          size: "medium",
        }),
        appearance: "success",
      });
      queryClient.invalidateQueries(["StoreDetail", storeId]);
    },
    onError: error => {
      console.log("onError", error);
    },
  });

  return mutation;
};
