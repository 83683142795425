import React from "react";

import { toISOStringKR } from "@/utils/toISOStringKR";

import { DateBox } from "src/components/block";

import { ExportButton, ExportTitle, ExportWrapper } from "../Export/index.styled";

interface DateFilterInterface {
  _onClick?: (start: string, end: string) => void;
  _date: { start: Date; end: Date };
  _setDate: (date: { start: Date; end: Date }) => void;
}

const EarlypayDateFilter = ({ _onClick, _date, _setDate }: DateFilterInterface) => {
  const buttonHandler = async (start: Date, end: Date) => {
    const startData = toISOStringKR(start);
    const endDate = toISOStringKR(end);

    _onClick(startData, endDate);
  };

  return (
    <div>
      <ExportTitle>선정산 필터</ExportTitle>
      <ExportWrapper>
        <DateBox _dateObj={_date} _setDateObj={_setDate} _month={false} />
        <ExportButton onClick={() => buttonHandler(_date.start, _date.end)}>조회하기</ExportButton>
        <ExportButton onClick={() => _setDate({ start: null, end: null })}>초기화</ExportButton>
      </ExportWrapper>
    </div>
  );
};

export default EarlypayDateFilter;
