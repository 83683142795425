import { themes } from "@carbon/themes";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export const Container = styled(ToastContainer)`
  .Toastify__toast-container {
    height: 48px;
  }

  .Toastify__toast {
    font-size: 14px;
    padding: 16px;
    color: #fff;
    background: ${themes.white.backgroundInverse};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Toastify__toast-icon {
    width: 22px;
    height: 22px;
    margin-inline-end: 16px;
  }

  .Toastify__toast--info {
    background: ${themes.white.backgroundInverse};
    border-left: 4px solid ${themes.white.supportInfoInverse};
    border-radius: 0;
  }

  .Toastify__toast--success {
    background: ${themes.white.backgroundInverse};
    border-left: 4px solid ${themes.white.supportSuccessInverse};
    border-radius: 0;
  }

  .Toastify__toast--error {
    background: ${themes.white.backgroundInverse};
    border-left: 4px solid ${themes.white.supportErrorInverse};
    border-radius: 0;
  }

  .Toastify__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;

    > svg {
      fill: white;
    }
  }
`;
