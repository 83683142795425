import React from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { IconProp } from "@atlaskit/button/dist/types/new-button/variants/types";
import Button from "@atlaskit/button/new";

import ChartBarIcon from "@atlaskit/icon/core/chart-bar";
import CustomizeIcon from "@atlaskit/icon/core/customize";
import DashboardIcon from "@atlaskit/icon/core/dashboard";
import LogOutIcon from "@atlaskit/icon/core/log-out";
import PersonIcon from "@atlaskit/icon/core/person";

import Avatar from "@atlaskit/avatar";
import { LeftSidebar } from "@atlaskit/page-layout";
import { Box, xcss } from "@atlaskit/primitives";
import { HeadingItem, Section } from "@atlaskit/side-navigation";

import { useNavigateSearch } from "@/hooks/useNavigateSearch";

import EarlypayLogo from "@/assets/images/earlypay-logo.png";

import { deleteToken } from "@/utils/token";

type NavType = {
  title: string;
  key: string;
  route?: string;
  icon?: IconProp;
  needSeperate?: boolean;
  children?: NavType[];
};

const Sidebar = () => {
  const location = useLocation();
  const { navigateSearch } = useNavigateSearch();
  const path: string = location.pathname.split("/")[1];
  const pathName: string = path === "" ? "home" : path;

  const handleLogout = (): void => {
    deleteToken("ep_tk");
    navigateSearch("/login");
  };

  const navigation: NavType[] = [
    {
      title: "",
      key: "settlementList",
      children: [
        {
          title: "대시보드(그라파나) ",
          route:
            "https://grafana.earlypay.co.kr/d/bi-home/ed9988?orgId=1&from=now-5y&to=now&timezone=browser&var-dw=ae59qp54relmoc&var-interval=7d&var-currencyUnit=1000000",
          icon: DashboardIcon,
          key: "grafana",
        },
        { title: "대시보드(레거시) ", route: "/legacy/dashboard", icon: DashboardIcon, key: "legacy" },
        { title: "정산 조정", route: "/settlement", icon: CustomizeIcon, key: "settlement" },
        { title: "퍼널분석", route: "/funnel", icon: ChartBarIcon, key: "funnel" },
        { title: "어드민", route: "/admin/store", icon: PersonIcon, key: "admin" },
      ],
    },
  ];

  const selectNav = (route: string) => {
    if (route.includes("https")) {
      return (window.location.href = route);
    }
    navigateSearch(route);
  };

  return (
    <LeftSidebar>
      <SidebarList>
        <Box>
          <Box style={{ display: "flex", alignItems: "center", gap: "12px", padding: "32px 0 8px 16px" }}>
            <Avatar appearance="square" size="small" src={EarlypayLogo} name="Earlypay" />
            <Box style={{ color: "#172B4D", fontWeight: 600, fontSize: "14px" }}>얼리페이</Box>
          </Box>
          {navigation.map(list => (
            <Section aria-labelledby="actions" key={list.key} hasSeparator={list.needSeperate}>
              <HeadingItem id="actions">{list.title}</HeadingItem>
              {list.children?.map(child => {
                const isSelected = child.key === pathName;
                return (
                  <Box key={child.title} xcss={containerStyles}>
                    <Button
                      isSelected={isSelected}
                      appearance={child.key === pathName ? "primary" : "subtle"}
                      iconBefore={() => (
                        <child.icon
                          label="fas"
                          size="small"
                          color={isSelected ? "var(--ds-icon-inverse)" : "var(--ds-icon)"}
                        />
                      )}
                      onClick={() => selectNav(child.route)}
                      shouldFitContainer
                    >
                      <Box xcss={textStyles}>{child.title}</Box>
                    </Button>
                  </Box>
                );
              })}
            </Section>
          ))}
        </Box>
        <Section aria-labelledby="actions" hasSeparator={true}>
          <Box xcss={containerStyles}>
            <Button
              appearance="subtle"
              iconBefore={() => <LogOutIcon label="fas" LEGACY_size="small" />}
              onClick={handleLogout}
              shouldFitContainer
            >
              <Box xcss={textStyles}>로그아웃</Box>
            </Button>
          </Box>
        </Section>
      </SidebarList>
    </LeftSidebar>
  );
};

export default Sidebar;

const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  background-color: white;
  height: 100%;
`;

const textStyles = xcss({
  textAlign: "left",
  paddingLeft: "space.025",
  fontSize: "14px",
});

const containerStyles = xcss({
  borderRadius: "3px",
  margin: "space.100",
});
