import React, { useState } from "react";

import { get_export_url, get_yogiyo_export_url } from "src/apis/admin";
import { instance } from "src/apis/instance";
import { DateBox, DropDwon, Spinner } from "src/components/block";

import { ExportButton, ExportTitle, ExportWrapper } from "./index.styled";

interface ExportInterface {
  _storeId: string;
}

const Export = ({ _storeId }: ExportInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [excelType, setExcelType] = useState([
    { key: "총정산", act: true },
    { key: "요기요", act: false },
  ]);
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
  });

  const storeId = _storeId;
  const choiceType = excelType.filter(type => type.act && type)[0].key;

  const getExportUrl = async (storeId: string, startDate: string, endDate: string) => {
    try {
      const res = await get_export_url(storeId, startDate, endDate);
      return res.url;
    } catch (err) {
      throw new Error(err);
    }
  };

  const getYogiyoExportUrl = async (storeId: string, startDate: string, endDate: string) => {
    try {
      const res = await get_yogiyo_export_url(storeId, startDate, endDate);
      return res.url;
    } catch (err) {
      const code = err.response.status;
      if (code === 422) alert("다시 시도해 주세요.");
      throw new Error(err);
    }
  };

  const excelButtonHandler = async (choiceType: string, storeId: string, start: Date, end: Date) => {
    const startDate = start.toISOString().split("T")[0];
    const endDate = end.toISOString().split("T")[0];
    let url;

    try {
      setIsLoading(true);
      Object.assign(instance.defaults, { timeout: 100000 });
      if (choiceType === "총정산") url = await getExportUrl(storeId, startDate, endDate);
      if (choiceType === "요기요") url = await getYogiyoExportUrl(storeId, startDate, endDate);
      window.open(url);
    } catch (err) {
      throw new Error(err);
    } finally {
      Object.assign(instance.defaults, { timeout: 8000 });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ExportTitle>엑셀다운로드</ExportTitle>
      <ExportWrapper>
        <DropDwon _onClick={setExcelType} _dropDownArr={excelType} />
        <DateBox _dateObj={date} _setDateObj={setDate} _month={false} />
        <ExportButton onClick={() => excelButtonHandler(choiceType, storeId, date.start, date.end)}>
          저장하기
        </ExportButton>
      </ExportWrapper>
      {isLoading && <Spinner />}
    </div>
  );
};

export default Export;
