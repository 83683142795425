import React, { useCallback } from "react";

import { Add as AddIcon, Save as SaveIcon, TrashCan as TrashCanIcon } from "@carbon/icons-react";
import { Button, ModalHeader, SelectItem, TableBody, TableHead, TableHeader, TableRow } from "@carbon/react";
import { useFieldArray, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { useAddDepositDetail } from "@/apis/deposit/postDepositdetail";

import { FormValues } from "@/types/formType";

import { getDate } from "@/utils/getFormattedDate";

import { selectedDepositDateState } from "@/recoil/deposit/atoms";
import { storeDetailState } from "@/recoil/stores/atoms";

import { DEPOSIT_COMPANY_LIST, DEPOSIT_TYPE_LIST, HEADER } from "./fieldKeyList";
import {
  ButtonWrapper,
  CustomModal,
  CustomSelect,
  CustomTableCell,
  CustomTableRow,
  CustomTextInput,
  DepositDetailTable,
  ModalWrapper,
  SubmitButtonWrapper,
} from "./index.styled";
import { defaultValues } from "./mock";

export interface AddDepositModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const AddDepositModal: React.FC<AddDepositModalProps> = ({ onClose, isVisible }) => {
  const storeData = useRecoilValue(storeDetailState);
  const selectedDepositDate = useRecoilValue(selectedDepositDateState);
  const today = getDate("now", "yyyy-mm-dd");

  const { handleSubmit, register, getValues, formState, control } = useForm({
    defaultValues: defaultValues,
  });
  const { fields, append, remove } = useFieldArray<FormValues, "deposits", "id">({
    name: "deposits",
    control: control,
  });

  const title = `${getDate(selectedDepositDate)} ${storeData.title} / 입출금 내역 추가`;
  const isEmpty = !getValues().deposits.length;
  const mutation = useAddDepositDetail(storeData.id);

  /** 입출금 내역 추가 함수 */
  const onSubmit = useCallback(
    (data: FormValues) => {
      mutation.mutate(data.deposits);
      onClose();
    },
    [mutation],
  );

  /** 입금 내역 항목 추가 함수 */
  const handleAddDepositData = () => {
    append(defaultValues.deposits[0]);
  };

  /** 입금 내역 항목 삭제 함수 */
  const handleDeleteDepositData = (index: number) => {
    remove(index);
  };

  return (
    <CustomModal
      size="md"
      open={isVisible}
      onRequestClose={onClose}
      onClose={onClose}
      aria-label="add-deposit-modal"
      modalHeading={title}
    >
      <ModalHeader title={title} />

      {/** 입출금 내역 추가 테이블 */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalWrapper>
          <DepositDetailTable>
            <TableHead>
              <TableRow>
                {HEADER.map((header, index) => (
                  <TableHeader key={index}>{header}</TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => {
                return (
                  <CustomTableRow key={field.id}>
                    {/** 입금사 선택 항목 */}
                    <CustomTableCell>
                      <CustomSelect
                        id={`deposit-company-select`}
                        noLabel
                        {...register(`deposits.${index}.cardCompany`)}
                      >
                        {DEPOSIT_COMPANY_LIST.map((label, index) => (
                          <SelectItem key={index} value={label} text={label} />
                        ))}
                      </CustomSelect>
                    </CustomTableCell>

                    {/** 입금일시 입력 항목 */}
                    <CustomTableCell>
                      <CustomTextInput
                        id="deposit-datetime-input"
                        type="datetime-local"
                        {...register(`deposits.${index}.depositedAt`, { required: true })}
                        min={`${today}T00:00`}
                        max={`${today}T23:59`}
                        disabled={!getValues().deposits[index].depositedAt.includes(getDate("now", "yyyy-mm-dd"))}
                      />
                    </CustomTableCell>

                    {/** 거래내역 타입 선택 항목 */}
                    <CustomTableCell>
                      <CustomSelect id={`deposit-type-select`} noLabel {...register(`deposits.${index}.trxType`)}>
                        {DEPOSIT_TYPE_LIST.map((label, index) => (
                          <SelectItem key={index} value={label} text={label} />
                        ))}
                      </CustomSelect>
                    </CustomTableCell>

                    {/** 입출금액 입력 항목 */}
                    <CustomTableCell>
                      <CustomTextInput
                        id="deposit-amount-input"
                        type="number"
                        defaultValue={`deposits.${index}.trxType`}
                        {...register(`deposits.${index}.amountDeposit`, { required: true, maxLength: 20 })}
                      />
                    </CustomTableCell>

                    {/** 입출금 내역 항목 삭제 버튼 */}
                    <CustomTableCell>
                      <Button
                        size="md"
                        kind="danger"
                        renderIcon={TrashCanIcon}
                        hasIconOnly
                        onClick={() => handleDeleteDepositData(index)}
                      />
                    </CustomTableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </DepositDetailTable>

          <ButtonWrapper>
            {/** 입금 내역 추가 버튼 */}
            <Button
              id="add-deposit-contents-button"
              size="md"
              type="button"
              kind="ghost"
              renderIcon={AddIcon}
              onClick={handleAddDepositData}
            >
              입금 내역 추가
            </Button>

            {/** 내역 추가 버튼 */}
            <SubmitButtonWrapper>
              <Button
                id="submit-add-deposit-button"
                type="submit"
                size="md"
                renderIcon={SaveIcon}
                disabled={!formState.isValid || isEmpty}
              >
                내역 추가
              </Button>
            </SubmitButtonWrapper>
          </ButtonWrapper>
        </ModalWrapper>
      </form>
    </CustomModal>
  );
};

export default AddDepositModal;
